import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { FaArrowAltCircleDown } from 'react-icons/fa';

import { getSelectedStore, getSelectedAddress } from '../../util';

const drawAddress = openAddress => {
  if (!getSelectedStore()) return <div />;
  if (getSelectedStore().type.toLowerCase() === 'takeaway') {
    return (
      <p
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: 'black',
          maxWidth: '100vw',
          overflow: 'hidden',
        }}
      >
        {getSelectedStore().address.replace(', 0 ()', '')}
      </p>
    );
  }
  if (getSelectedStore().type.toLowerCase() === 'delivery') {
    return (
      <Row style={{ cursor: 'pointer' }} onClick={openAddress}>
        <Col xs={12} style={{ display: 'flex' }}>
          <p
            style={{
              fontWeight: 'bold',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '90vw',
              overflow: 'hidden',
            }}
          >
            {`${getSelectedAddress().street}/${getSelectedAddress().number} ${
              getSelectedAddress().complement
            }`}
          </p>
          <FaArrowAltCircleDown style={{ marginLeft: 10, color: 'black' }} />
        </Col>
      </Row>
    );
  }
  return <div />;
};

const FixedAddress = ({
  hideAddress = false,
  onTakeAway,
  openAddress,
  config,
  onDelivery,
}) => {
  if (!config) {
    return <div />;
  }
  return (
    <>
      <div
        style={{
          width: '25%',
          position: 'fixed',
          zIndex: 12,
          left: 15,
          top: 107,
        }}
      >
        <Row style={{ position: 'absolute', bottom: 0 }}>
          {config.hasDelivery === 'YES' && (
            <Col xs={6}>
              <h1
                onClick={onTakeAway}
                style={{
                  cursor: 'pointer',
                  fontSize: 14,
                  color: config.textBaseColor,
                  fontWeight:
                    getSelectedStore() && getSelectedStore().type === 'delivery'
                      ? 'bold'
                      : 'normal',
                  borderBottom:
                    getSelectedStore() && getSelectedStore().type === 'delivery'
                      ? `1px solid ${config.headerUnderlineColor}`
                      : '0px',
                }}
              >
                Entrega
              </h1>
            </Col>
          )}
          {config.hasTakeAway === 'YES' && (
            <Col xs={6}>
              <h1
                onClick={onDelivery}
                style={{
                  cursor: 'pointer',
                  color: config.textBaseColor,
                  fontSize: 14,
                  fontWeight:
                    getSelectedStore() &&
                    getSelectedStore().type.toLowerCase() === 'takeaway'
                      ? 'bold'
                      : 'normal',
                  borderBottom:
                    getSelectedStore() &&
                    getSelectedStore().type.toLowerCase() === 'takeaway'
                      ? `1px solid ${config.headerUnderlineColor}`
                      : '0px',
                }}
              >
                Retirada
              </h1>
            </Col>
          )}
        </Row>
      </div>
      {!hideAddress && (
        <div
          style={{
            borderBottom: `1px solid ${config.headerBottomColor}`,
            padding: 10,
            zIndex: 12,
            position: 'fixed',
            top: 120,
            left: 0,
            right: 0,
            overflow: 'hidden',
            width: '100%',
            height: 40,
            backgroundColor: config.headerAddressBackgroundColor
              ? config.headerAddressBackgroundColor
              : '#FFF',
            fontSize: 14,
            textAlign: 'left',
          }}
        >
          {drawAddress(openAddress)}
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};
export default connect(mapStateToProps, {})(FixedAddress);
