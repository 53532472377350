import styled from "styled-components";
import { Card, Row } from "reactstrap";

export const CartCheckout = styled(Card)`
  margin-bottom: 20px;
  text-align: left;
  padding: 45px;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 0.87em;
  color: red;
  @media (max-width: 768px) {
    font-size: calc(8px + 1.5vmin);
  }
`;

export const StoreCard = styled(Card)`
  margin-top: 4px;
`;

export const ForecastTime = styled.span`
  font-size: 0.87em;
  @media (max-width: 768px) {
    font-size: calc(8px + 1.5vmin);
  }
`;

export const AddressLabel = styled.span`
  font-size: 0.87em;
  @media (max-width: 768px) {
    font-size: calc(8px + 1.5vmin);
  }
`;

export const CloseIcon = styled.span`
  color: black;
  cursor: pointer;
  float: left;
`;
export const CloseHideDesktopIcon = styled.span`
  color: black;
  cursor: pointer;
  float: left;
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`;

export const H4Destkop = styled.h4`
  display: inline;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const RowDesktop = styled(Row)`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const H4Mobile = styled.h4`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`;

export const RowMobile = styled(Row)`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const CartContainer = styled(Card)`
  padding: 0;
`;
