import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreditCardInput from 'react-credit-card-input';
import { confirmAlert } from 'react-confirm-alert';
import { detect } from 'detect-browser';
import { toast } from 'react-toastify';
import {
  CardBody,
  Row,
  Col,
  Modal,
  Button,
  Input,
  FormGroup,
  FormText,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardHeader,
} from 'reactstrap';
import InputMask from 'react-input-mask';

import moment from 'moment';
import {
  CloseIcon,
  CartCheckout,
  RowDesktop,
  CartContainer,
  RowMobile,
} from './style';
import {
  getUser,
  getCart,
  getSelectedStore,
  getSelectedAddress,
  calcTotalCart,
  toBlr,
  setTempIdOrders,
  setStorageUser,
  getStorageUser,
  validateCpf,
} from '../../util';
import {
  removeFromCart,
  clearCart,
  setConfigs,
  setFromCookie,
  // changeStore,
  applyPromoCode,
} from '../../redux/actions';
import {
  fetchUserDetail,
  fetchStorePayments,
  fetchCards,
  fetchAddressInformation,
  createNewAddress,
  sendOrder,
  checkPromocode,
  sendMetadata,
  createCard,
  fetchOpenHours,
  getColors,
} from '../../service';
import Loading from '../../components/loading';
import CartBody from '../../components/cart/body';
import CartFooter from '../../components/cart/footer';
import CurrencyInput from '../../components/currency-input';
import { NewAddress } from '../../components/address';
import { createOrder as parserOrder } from '../../util/order';
import Icons from './Icons';
import { trackEvent } from '../../util/track';

const browser = detect();

class Checkout extends Component {
  state = {
    customer: {
      cpf: '',
      customerId: 0,
      email: '',
      name: '',
      mainPhone: '',
    },
    cards: [],
    loadingCep: false,
    loading: true,
    newCard: false,
    change: 0,
    dates: [],
    height: 500,
    selectedPayment: {},
    isTakeAway: false,
    payTakeAway: false,
    openDiscount: false,
    isDelivery: true,
    loadingDeliveryInformation: false,
    addressInformation: {},
    paymentModal: false,
    loadingPayments: false,
    deliveryIsCoverage: false,
    orderErrorMessage: '',
    creatingCardLoading: false,
    deliveryFee: null,
    validatingPromoCode: false,
    cardError: {},
    paymentsOptions: {},
    payNow: false,
    deliveryStoreId: null,
    scheduledTime: null,
    openScheduleTimeModal: false,
    tempDayOfWeek: null,
    card: {},
    selectedStore: {},
    loadAddressFromCep: false,
    dayOfWeek: null,
    openHours1: [],
    openHours2: [],
    hasLogin: null,
    cpfRequired: null,
  };

  async componentDidMount() {
    const user = getUser();
    const store = getSelectedStore();
    const customer = getStorageUser();
    if (!this.props.config) {
      const colors = await getColors();
      this.props.setConfigs({ config: { ...colors } });
    }
    const {
      config: { hasLogin, cpfRequired },
    } = this.props;
    this.setState({ hasLogin, cpfRequired });
    if (customer) this.setState({ customer });

    if (hasLogin === 'YES' && !user) {
      this.props.history.push(`/${process.env.REACT_APP_PROJECT}/login`);
      return;
    }

    const { openHours1, openHours2 } = await fetchOpenHours({
      token: user ? user.token : process.env.REACT_APP_TOKEN,
      storeId: store.id || store.storeId,
      hasLogin,
    });

    const openHours1Replaced = openHours1
      ? openHours1.map(open => open && open.replace('|', ' - '))
      : null;

    const openHours2Replaced = openHours2
      ? openHours2.map(open => open && open.replace('|', ' - '))
      : null;

    this.setState({
      openHours1: openHours1Replaced,
      openHours2: openHours2Replaced,
    });

    if (!getSelectedAddress() && getSelectedStore().type === 'delivery') {
      this.props.history.push('/');
      return;
    }
    if (!getSelectedStore()) {
      this.props.history.push('/');
      return;
    }
    // this.props.changeStore({ store: getSelectedStore() });

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.loadData();
    const colors = await getColors();
    this.props.setConfigs({ config: { ...colors } });
    this.createDates();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.props.applyPromoCode({ code: null, percent: 0, promocodeId: null });
  }

  createDates = () => {
    const dates = [
      moment().add(
        parseInt(this.props.config.hoursAfterShceduled, 10),
        'hours'
      ),
    ];

    const vacationTimes = [
      '1702',
      '1802',
      '1902',
      '2002',
      '2102',
      '2202',
      '2302',
    ];

    if (
      vacationTimes.includes(moment().format('DDMM')) &&
      parseInt(process.env.REACT_APP_MERCHANT_ID, 10) === 31
    ) {
      dates[0] = moment('24/02/2021', 'DD/MM/YYYY');
    }
    const getVacationTime = daysToAdd => {
      const newDate = moment(dates[0]).add(daysToAdd, 'days');
      const formated = newDate.format('DDMM');
      const index = vacationTimes.findIndex(vacation => vacation === formated);
      if (
        index !== -1 &&
        parseInt(process.env.REACT_APP_MERCHANT_ID, 10) === 31
      ) {
        newDate.add(vacationTimes.length + 1 - daysToAdd + index, 'days');
      }

      return newDate;
    };
    dates.push(getVacationTime(1));
    dates.push(getVacationTime(2));
    dates.push(getVacationTime(3));
    dates.push(getVacationTime(4));
    if (parseInt(process.env.REACT_APP_MERCHANT_ID, 10) === 149) {
      const diffDate = moment('12/06/2021', 'DD/MM/YYYY').diff(
        dates[dates.length - 1],
        'days'
      );
      for (let i = 0; i <= diffDate; i += 1) {
        dates.push(getVacationTime(4 + i + 1));
      }
    }
    if (parseInt(process.env.REACT_APP_MERCHANT_ID, 10) === 156) {
      const diffDate = moment('09/05/2021', 'DD/MM/YYYY').diff(
        dates[dates.length - 1],
        'days'
      );
      for (let i = 0; i <= diffDate; i += 1) {
        dates.push(getVacationTime(4 + i + 1));
      }
    }

    if (this.props.config.scheduledAllowToday === 'YES') {
      dates.unshift(moment());
    }
    this.setState({ dates });
  };

  loadData = async () => {
    const user = getUser();
    const { hasLogin } = this.state;
    const cookieCart = getCart();
    if (cookieCart) {
      this.props.setFromCookie(cookieCart);
    }
    if (hasLogin === 'YES') {
      const customer = await fetchUserDetail({
        token: user ? user.token : process.env.REACT_APP_TOKEN,
      });
      this.setState({ customer });
    }
    const selectedStore = getSelectedStore();
    const [paymentsOptions, { cards }] = await Promise.all([
      fetchStorePayments(selectedStore.storeId || selectedStore.id),
      fetchCards(
        user ? user.token : process.env.REACT_APP_TOKEN,
        getSelectedStore().id || getSelectedStore().storeId,
        hasLogin
      ),
    ]);

    this.setState({
      loading: false,
      selectedStore,
      paymentsOptions,
      cards,
      isTakeAway: selectedStore.type.toLowerCase() === 'takeaway',
      isDelivery: selectedStore.type.toLowerCase() === 'delivery',
    });
    console.log('selectedStore.type: ', selectedStore.type);
    if (selectedStore.type.toLowerCase() === 'takeaway') {
      this.setState({ deliveryFee: 0 });
    } else {
      this.fetchDeliveryData();
    }
  };

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  fetchDeliveryData = async () => {
    const { isDelivery } = this.state;
    console.log('isDelivery: ', isDelivery);
    // this.setState({ loadingDeliveryInformation: true });
    if (isDelivery) {
      // console.log("data: ", data);
      this.setState(
        {
          deliveryStoreId: getSelectedStore().id || getSelectedStore().storeId,
          deliveryFee: getSelectedStore().deliveryFee,
          deliveryIsCoverage: true,
          orderErrorMessage: null,
        },
        () => console.log('deliveryFee: ', this.state.deliveryFee)
      );
    }
    // this.setState({ loadingDeliveryInformation: false });
  };

  openPaymentOptions = async () => {
    this.setState({
      paymentModal: true,
    });
  };

  payNow = () => {
    trackEvent({
      name: 'payWithCard',
      data: { user: getUser() ? getUser() : { name: 'Anônimo' } },
    });

    this.setState({ payNow: true, payTakeAway: false });
  };

  payTakeAway = () => {
    trackEvent({
      name: 'payWithOnDelivery',
      data: { user: getUser() ? getUser() : { name: 'Anônimo' } },
    });
    this.setState({ payTakeAway: true, payNow: false });
  };

  handleChangeCardInformation =
    cardField =>
    ({ target: { value } }) => {
      console.log('length: ', `${value}`.length);
      if (cardField === 'cvv' && `${value}`.length > 4) return;
      const { card, cardError } = this.state;
      card[cardField] = value;
      cardError[cardField] = false;
      this.setState({ card, cardError });
    };

  removeProduct = async product => {
    confirmAlert({
      title: 'Remover produto',
      message: 'Deseja remover este produto do carrinho?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            this.props.removeFromCart(product.cartId);
          },
        },
        {
          label: 'Não',
          onClick: () => {},
        },
      ],
    });
  };

  createNewCreditCard = async () => {
    const { card } = this.state;
    const user = getUser();
    this.setState({ creatingCardLoading: true });
    const createdCard = await createCard(
      user ? user.token : process.env.REACT_APP_TOKEN,
      {
        cardNumber: card.number.split(' ').join(''),
        cardExpirationDate: card.expiry.split(' ').join(''),
      }
    );
    const { cards } = await fetchCards(
      user ? user.token : process.env.REACT_APP_TOKEN,
      getSelectedStore().id || getSelectedStore().storeId
    );

    this.setState({
      cards,
      creatingCardLoading: false,
      newCard: false,
      cardError: {},
      card: {
        id: createdCard.id,
      },
    });
  };

  finishOrder = async () => {
    const {
      customer,
      deliveryFee,
      change,
      card,
      payNow,
      isTakeAway,
      selectedStore,
      cpfRequired,
      hasLogin,
    } = this.state;
    this.setState({ loadingOrder: true });

    if (!customer.name) {
      alert('Nome obrigatório.');
      this.setState({ loadingOrder: false });
      return;
    }

    if (!customer.mainPhone) {
      alert('Telefone obrigatório.');
      this.setState({ loadingOrder: false });
      return;
    }

    if (cpfRequired === 'YES' && !validateCpf(customer.cpf)) {
      alert('o CPF não é válido');
      this.setState({ loadingOrder: false });
      return;
    }

    const { cart } = this.props;
    const user = getUser();
    if (payNow && !card.id && hasLogin === 'YES') {
      const createdCard = await createCard(
        user ? user.token : process.env.REACT_APP_TOKEN,
        {
          cardNumber: card.number.split(' ').join(''),
          cardExpirationDate: card.expiry.split(' ').join(''),
        }
      );
      card.id = createdCard.id;
    }

    const selectedAddress = getSelectedAddress();
    if (selectedAddress) {
      delete selectedAddress.canEditStreet;
    }
    const order = parserOrder({
      cartProducts: cart.products,
      discount: cart.discount,
      scheduled: this.props.config.hasScheduledOrder === 'YES',
      dayOfWeek: this.state.dayOfWeek,
      timeOfDay: this.state.selectUtcTime,
      discountId: -1,
      promocodeId: cart.promocodeId,
      address: selectedAddress,
      customer: {
        id: customer.id,
        name: customer.name,
        cpf: customer.cpf,
        email: customer.email,
        mainPhone: customer.mainPhone.replace(/\D/g, ''),
      },
      customerId: customer.id,
      deliveryFee,
      storeId: this.state.deliveryStoreId || selectedStore.id,
      change: change * 100,
      isTakeAway: selectedStore.type.toLowerCase() === 'takeaway',
      payment: this.state.selectedPayment,
    });
    if (payNow) {
      order.card = {
        ...card,
        id: card.id,
        cvv: card.cvv,
      };
    }
    if (change && change * 100 < order.total) {
      alert('Valor pago em dinheiro precisa ser igual ou maior ao pedido.');
      this.setState({ loadingOrder: false });
      return;
    }
    if (isTakeAway) {
      this.setState({ paymentModal: false });
      confirmAlert({
        title: 'Buscar pedido na loja',
        message: `Você confirma que vai retirar o pedido na loja ${selectedStore.name} - ${selectedStore.address}?`,
        buttons: [
          {
            label: 'Sim',
            onClick: () => {
              this.setState({ paymentModal: true });
              this.createOrder(order);
            },
          },
          {
            label: 'Não',
            onClick: () => {
              this.setState({
                loadingOrder: false,
                loading: false,
                change: null,
                payNow: false,
                payTakeAway: false,
                selectedPayment: { id: null },
                paymentModal: false,
                paymentsOptions: {},
              });
            },
          },
        ],
      });
    } else {
      this.createOrder(order);
    }
  };

  createOrder = async order => {
    const { selectedStore, customer } = this.state;
    const {
      config: { hasLogin },
    } = this.props;
    const user = getUser();
    if (hasLogin === 'NO') {
      setStorageUser(customer);
    }
    const result = await sendOrder(
      this.state.deliveryStoreId || selectedStore.id,
      order,
      user ? user.token : process.env.REACT_APP_TOKEN,
      hasLogin
    );
    if (result.id) {
      trackEvent({
        name: 'Purchase',
        data: { currency: 'BRL', value: order.total / 100 },
      });

      await sendMetadata(result.id, {
        platform: browser.name,
        os: browser.os,
        platformVersion: browser.version,
        appVersion: process.env.VERSION,
        log: JSON.stringify(this.state),
      });
      this.props.clearCart();

      setTempIdOrders(result.id, hasLogin === 'YES');
      this.props.history.push(`/success/${result.id}`);
    } else {
      trackEvent({
        name: 'completedOrderWithError',
        data: { user: user || { name: 'Anônimo' }, error: result.erro },
      });

      this.setState({
        loadingOrder: false,
        loading: false,
        payNow: false,
        change: null,
        payTakeAway: false,
        selectedPayment: { id: null },
        paymentModal: false,
        paymentsOptions: {},
      });

      if (result.erro) {
        this.loadData();
        alert(result.erro);
      } else {
        this.loadData();
        alert('Erro ao finalizar pedido, tente novamente.');
      }
    }
  };

  validateCupom = async e => {
    e.preventDefault();
    const { selectedStore } = this.state;
    const { cart } = this.props;

    const orderValue = calcTotalCart({
      cartProducts: cart.products,
      deliverFee: 0,
      format: false,
    });

    this.setState({ validatingPromoCode: true });
    const user = getUser();
    const result = await checkPromocode({
      code: this.state.promoCode,
      orderValue,
      token: user ? user.token : null,
      storeId: this.state.deliveryStoreId || selectedStore.id,
    });
    this.setState({ validatingPromoCode: false, openDiscount: false });

    if (result.msg) {
      alert(result.msg);
      return;
    }
    this.props.applyPromoCode({
      code: result.code,
      percent: result.percentValue,
      money: result.moneyValue,
      discountDescription: result.percentValue
        ? `${result.name} (${result.percentValue * 100}%)`
        : `${result.name} (${toBlr(result.moneyValue)})`,
      promocodeId: result.id,
    });
  };

  createAddress = async e => {
    e.preventDefault();
    this.setState({ loadingCep: true });
    const user = getUser();
    const address = {
      ...this.state.addressInformation,
      cep: this.state.addressInformation.zip,
    };
    delete address.zip;
    delete address.canEditStreet;
    await createNewAddress({
      body: {
        address,
      },
      token: user ? user.token : process.env.REACT_APP_TOKEN,
    });
    this.setState({
      loadingCep: false,
      newAddressPopup: false,
      loading: true,
    });
    const customer = await fetchUserDetail({
      token: user ? user.token : process.env.REACT_APP_TOKEN,
    });
    this.setState({ customer, loading: false, addressSelectModal: true });
  };

  loadAddressFromCep = async zipCode => {
    this.setState({ loadAddressFromCep: true });
    const addressInformation = await fetchAddressInformation(zipCode)
      .then(addressInformation => ({
        ...addressInformation,
        canEditStreet:
          !addressInformation.street || addressInformation.street.trim() === '',
        number: '',
        complement: '',
      }))
      .catch(() => ({
        error: 'Cep não encontrado',
      }));

    this.setState({ loadAddressFromCep: false, addressInformation });
  };

  convertToLocalDate = ({ index, date }) => {
    const day = moment()
      .startOf('week')
      .add(index || 1, 'day')
      .format('DD');
    const month = moment().format('MM');
    const year = moment().format('YYYY');

    console.log(`hour index: ${index}`);

    const convertedDate = moment(
      `${day}/${month}/${year} ${date} +0000`,
      'DD/MM/YYYY HH:mm Z'
    );
    convertedDate.day(day);
    convertedDate.month(month);
    return convertedDate;
  };

  openHourSelection = date => {
    this.setState({ openScheduleTimeModal: true, tempDayOfWeek: date });
  };

  selectTime = () => {
    if (!this.state.selectUtcTime) {
      toast.warn('Você precisa selecionar um horário');
      return;
    }
    this.setState({
      dayOfWeek: this.state.tempDayOfWeek.format('DD/MM'),
      openScheduleTimeModal: false,
    });
  };

  drawPossibleTimes = () => {
    const { tempDayOfWeek, openHours1 } = this.state;
    if (!tempDayOfWeek || !openHours1) return null;
    console.log('hour      : ', openHours1[tempDayOfWeek.weekday()]);
    const start = this.convertToLocalDate({
      index: tempDayOfWeek.weekday(),
      date: openHours1[tempDayOfWeek.weekday()].split('-')[0],
    });
    console.log('hour start local format: ', start.local().format());
    console.log('hour start format: ', start.format());

    const end = this.convertToLocalDate({
      index: tempDayOfWeek.weekday(),
      date: openHours1[tempDayOfWeek.weekday()].split('-')[1],
    });

    const isToday = tempDayOfWeek.format('DD/MM') === moment().format('DD/MM');

    if (
      isToday &&
      parseInt(start.format('H')) >= parseInt(moment().local().format('H'))
    ) {
      const toAdd =
        parseInt(moment().local().format('H')) -
        parseInt(start.format('H')) +
        1;
      if (toAdd > 0) {
        console.log('hour # adding: ', toAdd);
        start.add(toAdd, 'hours');
      }
    }
    console.log('hour diff: ', end.diff(start, 'hours'));
    const possibleTimes = [];
    while (end.diff(start, 'hours') >= 0) {
      possibleTimes.push(moment(start));
      start.add('1', 'hours');
    }
    if (possibleTimes.length === 0)
      return (
        <Label>
          Não existe mais horário disponível para o dia atual, por favor,
          selecione outro dia
        </Label>
      );
    return possibleTimes.map(time => (
      <FormGroup check key={`time_${time.format('HH')}`}>
        <Input
          type="radio"
          name="selectedTime"
          id={time.format('HH')}
          onClick={() => this.setState({ selectUtcTime: time.utc() })}
        />
        <Label check for={time.format('HH')}>{`${time.format('HH')}h`}</Label>
      </FormGroup>
    ));
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState(prev => ({
      customer: {
        ...prev.customer,
        [name]: value,
      },
    }));
  };

  render() {
    const {
      discount,
      loadingDeliveryInformation,
      isTakeAway,
      cardError,
      payTakeAway,
      loading,
      paymentModal,
      loadingPayments,
      paymentsOptions,
      change,
      creatingCardLoading,
      selectedPayment,
      loadingOrder,
      openHours2,
      cards,
      card,
      openHours1,
      dates,
      payNow,
      customer,
    } = this.state;
    const { cart } = this.props;
    const canNotFinishOrder =
      (payNow && (cardError.number || cardError.cvv || cardError.expiry)) ||
      (!payNow && !selectedPayment.id) ||
      (payNow && !card.id && (!card.cvv || card.cvv === '')) ||
      (payNow && card.id && (!card.cvv || card.cvv === ''));
    if (!this.props.config) {
      return <Loading message="Carregando informações..." />;
    }

    const translate = {
      0: 'Domingo',
      1: 'Segunda-feira',
      2: 'Terça-feira',
      3: 'Quarta-feira',
      4: 'Quinta-feira',
      5: 'Sexta-feira',
      6: 'Sabado',
    };
    const payBody = (
      <>
        {!loadingPayments && !loadingOrder && (
          <>
            {this.props.config.hoursAfterShceduled && (
              <Row>
                <Col xs={12}>
                  Qual o melhor dia para{' '}
                  {this.state.isDelivery ? 'entregar' : 'retirar na loja'} suas
                  compras?
                </Col>
                <div>
                  {dates
                    .filter(
                      date =>
                        !this.state.openHours1 ||
                        this.state.openHours1[date.weekday()]
                    )
                    .map(date => (
                      <Button
                        key={`${date.weekday()}`}
                        outline
                        onClick={() => this.openHourSelection(date)}
                        style={{
                          width: 250,
                          margin: 4,
                          color:
                            this.state.dayOfWeek === date.format('DD/MM')
                              ? this.props.config.bottomTextColor
                              : 'black',
                          backgroundColor:
                            this.state.dayOfWeek === date.format('DD/MM')
                              ? this.props.config.bottomBackgroundColor
                              : '',
                        }}
                        disabled={this.state.dayOfWeek === date.format('DD/MM')}
                      >
                        <div>
                          <div>{date.format('DD/MM')}</div>
                          <div>{translate[date.weekday()]}</div>
                          {this.state.selectUtcTime &&
                            this.state.dayOfWeek === date.format('DD/MM') && (
                              <div>
                                {`${this.state.selectUtcTime
                                  .local()
                                  .format('HH')}h`}
                              </div>
                            )}
                          {this.state.dayOfWeek !== date.format('DD/MM') && (
                            <div>
                              {this.state.openHours1 &&
                              this.state.openHours1[date.weekday()]
                                ? `${this.convertToLocalDate({
                                    index: date.weekday(),
                                    date: openHours1[date.weekday()].split(
                                      '-'
                                    )[0],
                                  }).format('HH:mm -')}
                            ${this.convertToLocalDate({
                              index: date.weekday(),
                              date: openHours1[date.weekday()].split('-')[1],
                            }).format(' HH:mm')}`
                                : '12:00 - 18:00'}
                              {this.state.openHours2 &&
                              this.state.openHours2[date.weekday()]
                                ? `e ${this.convertToLocalDate({
                                    index: date.weekday(),
                                    date: openHours2[date.weekday()].split(
                                      '-'
                                    )[0],
                                  }).format('HH:mm -')}
                          ${this.convertToLocalDate({
                            index: date.weekday(),
                            date: openHours2[date.weekday()].split('-')[1],
                          }).format(' HH:mm')}`
                                : ''}
                            </div>
                          )}
                        </div>
                      </Button>
                    ))}
                </div>
              </Row>
            )}
            <Row
              style={{
                marginTop: 12,
                display:
                  this.state.dayOfWeek === null &&
                  this.props.config.hoursAfterShceduled
                    ? 'none'
                    : 'flex',
              }}
            >
              <Col xs={12}>
                <span>Como você deseja pagar este pedido?</span>
              </Col>
              <Col xs={12}>
                {paymentsOptions.appPayment && (
                  <Button
                    onClick={this.payNow}
                    outline
                    style={{
                      width: 180,
                      margin: 4,
                      opacity: 1,
                      color: payNow
                        ? this.props.config.bottomTextColor
                        : 'black',
                      backgroundColor: payNow
                        ? this.props.config.bottomBackgroundColor
                        : '',
                    }}
                    disabled={payNow}
                  >
                    Pagar Agora (Online)
                  </Button>
                )}
                {paymentsOptions.payments &&
                  paymentsOptions.payments.length > 0 && (
                    <Button
                      outline
                      style={{
                        width: 150,
                        margin: 4,
                        opacity: 1,
                        color: payTakeAway
                          ? this.props.config.bottomTextColor
                          : 'black',
                        backgroundColor: payTakeAway
                          ? this.props.config.bottomBackgroundColor
                          : '',
                      }}
                      onClick={this.payTakeAway}
                      disabled={payTakeAway}
                    >
                      Pagar na {isTakeAway ? 'retirada' : 'entrega'}
                    </Button>
                  )}
              </Col>
            </Row>
          </>
        )}
        {payTakeAway && !loadingOrder && (
          <>
            <Row style={{ marginTop: 28 }}>
              <Col xs={12}>
                <span>Selecione a forma de pagamento</span>
              </Col>
              {selectedPayment.methodKey === 'dinheiro' && (
                <Col xs={12}>
                  <span>Troco para quanto?</span>
                  <CurrencyInput
                    name="change"
                    value={change}
                    onChange={(event, value) =>
                      this.setState({ change: value })
                    }
                  />
                  <FormText>
                    Informe o total do pagamento em dinheiro, por exemplo R$
                    100,00
                  </FormText>
                </Col>
              )}
            </Row>
            <Row>
              {paymentsOptions.payments.map(payment => {
                const disabled = selectedPayment.id === payment.id;
                return (
                  <Col
                    xs={12}
                    lg={6}
                    key={`payment_${payment.id}`}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      disabled={disabled}
                      outline
                      style={{
                        width: '90%',
                        marginTop: 4,
                        opacity: 1,
                        color: disabled
                          ? this.props.config.bottomTextColor
                          : 'black',
                        backgroundColor: disabled
                          ? this.props.config.bottomBackgroundColor
                          : '',
                      }}
                      onClick={() =>
                        this.setState({ selectedPayment: payment })
                      }
                    >
                      {payment.name}
                    </Button>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
        {payNow && !loadingOrder && cards.length > 0 && (
          <Row>
            <Col xs={12}>Cartões cadastrados</Col>
            <Col xs={12}>
              <hr />
            </Col>
          </Row>
        )}
        {payNow &&
          !loadingOrder &&
          cards.map(cardView => (
            <Row key={`card_${cardView.cardLastDigits}`}>
              <Col xs={4} lg={2}>
                <FormGroup check style={{ textAlign: 'left' }}>
                  <Label check>
                    <Input
                      type="radio"
                      checked={cardView.id === card.id}
                      style={{ width: 20, height: 20 }}
                      onChange={() => {}}
                      onClick={({ target: { value, checked } }) => {
                        const sameCard = card.id === cardView.id;
                        this.setState({
                          card: {
                            id: sameCard ? null : cardView.id,
                            cvv: sameCard ? null : cardView.cvv,
                          },
                        });
                      }}
                    />
                    <img
                      style={{ width: 30, marginLeft: 8 }}
                      src={Icons[cardView.brand]}
                      alt="Cartão de crédito"
                    />
                  </Label>
                </FormGroup>
              </Col>

              <Col xs={6} lg={3} style={{ textAlign: 'left' }}>
                {cardView.cardLastDigits}
              </Col>
              {cardView.id === card.id && (
                <Col lg={7} xs={12}>
                  <FormGroup>
                    <Label>Informe o código de segurança</Label>
                    <Input
                      style={{ width: 150 }}
                      type="number"
                      maxLength={4}
                      value={card.cvv}
                      onChange={this.handleChangeCardInformation('cvv')}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
          ))}
        {payNow && !loadingOrder && cards.length > 0 && (
          <Row>
            <Col xs={12}>
              <Button onClick={() => this.setState({ newCard: true })} outline>
                Cadastrar novo cartão
              </Button>
            </Col>
          </Row>
        )}
        {!loadingOrder && payNow && cards.length === 0 && (
          <Row style={{ marginTop: 28 }}>
            <Col xs={12}>
              <span>Informe os dados do cartão de crédito</span>
            </Col>
            <Col xs={12}>
              <CreditCardInput
                cardNumberInputProps={{
                  disabled: cardError.expiry || cardError.cvv,
                  onError: () =>
                    this.setState({
                      cardError: {
                        ...this.state.cardError,
                        number: true,
                      },
                    }),
                  onChange: this.handleChangeCardInformation('number'),
                }}
                cardExpiryInputProps={{
                  disabled: cardError.number || cardError.cvv,
                  onError: () =>
                    this.setState({
                      cardError: {
                        ...this.state.cardError,
                        expiry: true,
                      },
                    }),
                  onChange: this.handleChangeCardInformation('expiry'),
                }}
                cardCVCInputProps={{
                  disabled: cardError.expiry || cardError.number,
                  maxLength: 4,
                  onError: () =>
                    this.setState({
                      cardError: {
                        ...this.state.cardError,
                        cvv: true,
                      },
                    }),
                  onChange: this.handleChangeCardInformation('cvv'),
                }}
                fieldClassName="input"
                customTextLabels={{
                  invalidCardNumber: 'Número do cartão é inválido',
                  expiryError: {
                    invalidExpiryDate: 'Data de vencimento',
                    monthOutOfRange: 'Mês do vencimento, entre 01 e 12',
                    yearOutOfRange: 'Ano do vencimento do cartão',
                  },
                  invalidCvc: 'Código de segurança inválido',
                  cardNumberPlaceholder: 'Número do cartão',
                  expiryPlaceholder: 'MM/AA',
                  cvcPlaceholder: 'COD',
                }}
              />
            </Col>
          </Row>
        )}
      </>
    );

    if (loading) {
      return <Loading message="Carregando informações..." />;
    }
    return (
      <>
        <Modal isOpen={this.state.openScheduleTimeModal} size="lg">
          <ModalHeader>Qual é o horário desejado?</ModalHeader>
          <ModalBody>{this.drawPossibleTimes()}</ModalBody>
          <ModalFooter>
            <Button
              outline
              color="danger"
              onClick={() =>
                this.setState({
                  openScheduleTimeModal: false,
                  selectUtcTime: null,
                })
              }
            >
              Cancelar
            </Button>
            <Button onClick={this.selectTime}>Selecionar horário</Button>
          </ModalFooter>
        </Modal>
        <NewAddress
          createAddress={this.createAddress}
          addressInformation={this.state.addressInformation}
          loadingCep={this.state.loadingCep}
          onChange={field => value => {
            this.setState({ [field]: value });
            if (
              field === 'cep' &&
              value.replace('_', '').replace('-', '').length === 8
            ) {
              const cep = value.replace('_', '').replace('-', '');
              this.loadAddressFromCep(cep);
            }
            if (field !== 'cep') {
              this.setState({
                addressInformation: {
                  ...this.state.addressInformation,
                  [field]: value,
                },
              });
            }
          }}
          toggle={() => this.setState({ newAddressPopup: false })}
          isOpen={this.state.newAddressPopup}
        />

        <Modal isOpen={this.state.newCard} size="sm">
          <ModalBody>
            {creatingCardLoading && <Loading message="Salvando novo cartão" />}
            {!creatingCardLoading && (
              <Row style={{ marginTop: 28 }}>
                <Col xs={12}>
                  <span>Informe os dados do cartão de crédito</span>
                </Col>
                <Col xs={12}>
                  <CreditCardInput
                    cardCVCInputProps={{ type: 'hidden' }}
                    cardNumberInputProps={{
                      disabled: cardError.expiry || cardError.cvv,
                      onError: () =>
                        this.setState({
                          cardError: {
                            ...this.state.cardError,
                            number: true,
                          },
                        }),
                      onChange: this.handleChangeCardInformation('number'),
                    }}
                    cardExpiryInputProps={{
                      disabled: cardError.number || cardError.cvv,
                      onError: () =>
                        this.setState({
                          cardError: {
                            ...this.state.cardError,
                            expiry: true,
                          },
                        }),
                      onChange: this.handleChangeCardInformation('expiry'),
                    }}
                    fieldClassName="input"
                    customTextLabels={{
                      invalidCardNumber: 'Número do cartão é inválido',
                      expiryError: {
                        invalidExpiryDate: 'Data de vencimento',
                        monthOutOfRange: 'Mês do vencimento, entre 01 e 12',
                        yearOutOfRange: 'Ano do vencimento do cartão',
                      },
                      cardNumberPlaceholder: 'Número do cartão',
                      expiryPlaceholder: 'MM/AA',
                    }}
                  />
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              outline
              disabled={creatingCardLoading}
              color="danger"
              onClick={() => this.setState({ newCard: false, cardError: {} })}
            >
              Cancelar
            </Button>
            <Button
              disabled={
                creatingCardLoading || cardError.expiry || cardError.number
              }
              onClick={this.createNewCreditCard}
            >
              Salvar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={paymentModal}
          size="lg"
          toggle={() =>
            this.setState({
              resumeCheckoutModal: true,
              payNow: false,
              change: null,
              payTakeAway: false,
              selectedPayment: { id: null },
              paymentModal: false,
              paymentsOptions: {},
            })
          }
        >
          <ModalBody>
            {loadingPayments && (
              <Loading message="Carregando formas de pagamento" />
            )}
            {!loadingPayments && !loadingOrder && (
              <header
                style={{
                  position: 'sticky',
                  top: -20,
                  zIndex: 10,
                  backgroundColor: 'white',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <hr />

                  <CloseIcon
                    onClick={() =>
                      this.setState({
                        resumeCheckoutModal: true,
                        payNow: false,
                        payTakeAway: false,
                        selectedPayment: { id: null },
                        paymentModal: false,
                        change: null,
                        paymentsOptions: {},
                      })
                    }
                  >
                    Voltar
                  </CloseIcon>
                  <span style={{ fontWeight: 'bold' }}>Pagamento.</span>
                  <hr />
                </div>
              </header>
            )}
            {!loadingPayments && !loadingOrder && (
              <CartFooter
                cart={cart}
                discount={discount}
                deliveryFee={this.state.deliveryFee}
              />
            )}
            {!loadingOrder && payBody}
            {loadingOrder && <Loading message="Criando pedido.." />}
          </ModalBody>
          {!loadingOrder && (
            <ModalFooter>
              <Button
                onClick={this.finishOrder}
                disabled={canNotFinishOrder || loadingOrder}
                style={{
                  color: this.props.config.bottomTextColor,
                  backgroundColor: this.props.config.bottomBackgroundColor,
                }}
              >
                Finalizar pedido
              </Button>
              {payNow && canNotFinishOrder && (
                <FormText>
                  <span style={{ color: 'red' }}>
                    Você precisa escolher ou preencher os dados do seu cartão de
                    crédito
                  </span>
                </FormText>
              )}
              {!selectedPayment.id && !payNow && (
                <FormText>
                  <span style={{ color: 'red' }}>
                    Você precisa selecionar ao menos uma forma de pagamento
                  </span>
                </FormText>
              )}
            </ModalFooter>
          )}
        </Modal>

        <CartCheckout>
          {!loadingOrder && <h1>Finalize seu pedido</h1>}
          {loadingOrder && <Loading message="Criando pedido.." />}
          {!loadingOrder && (
            <CardBody style={{ padding: 0, overflow: 'hidden' }}>
              {loadingDeliveryInformation && (
                <Loading message="Carregando informações de delivery" />
              )}
              {!loadingDeliveryInformation && (
                <Row style={{ margin: 0 }}>
                  <Col xs={12} lg={7} className="order-1 order-lg-0">
                    <h4>Endereço de {isTakeAway ? 'Retirada ' : 'Entrega'}</h4>
                    <Row>
                      <Col xs={12}>
                        {isTakeAway
                          ? getSelectedStore().address
                          : `${getSelectedAddress().street} ${
                              getSelectedAddress().number
                            } ${getSelectedAddress().complement}`}
                      </Col>
                      {!isTakeAway && (
                        <Col xs={12}>{`${getSelectedAddress().city}/${
                          getSelectedAddress().state
                        }`}</Col>
                      )}
                    </Row>
                    {this.state.hasLogin === 'NO' && (
                      <Row style={{ marginTop: 20 }}>
                        <Col xs={6}>
                          <FormGroup>
                            <Label>Nome *</Label>
                            <Input
                              name="name"
                              type="text"
                              value={customer.name}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            <Label>Telefone *</Label>
                            <InputMask
                              mask="(99)99999.9999"
                              value={customer.mainPhone}
                              name="mainPhone"
                              onChange={this.handleChange}
                            >
                              {inputProps => <Input {...inputProps} />}
                            </InputMask>
                          </FormGroup>
                        </Col>

                        {this.state.cpfRequired === 'YES' && (
                          <Col xs={6}>
                            <FormGroup>
                              <Label>CPF *</Label>
                              <InputMask
                                mask="999.999.999-99"
                                value={customer.cpf}
                                name="cpf"
                                onChange={this.handleChange}
                              >
                                {inputProps => <Input {...inputProps} />}
                              </InputMask>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    )}

                    <RowMobile style={{ margin: 10, justifyContent: 'center' }}>
                      <Button
                        outline
                        style={{ width: '80%', marginBottom: 20 }}
                        onClick={this.openPaymentOptions}
                      >
                        Pagamento
                      </Button>
                    </RowMobile>
                    <RowDesktop style={{ padding: 45 }}>
                      {payBody}
                      <Col xs={12}>
                        <Button
                          style={{
                            marginTop: 80,
                            width: '100%',
                            color: this.props.config.bottomTextColor,
                            backgroundColor:
                              this.props.config.bottomBackgroundColor,
                          }}
                          onClick={this.finishOrder}
                          disabled={canNotFinishOrder || loadingOrder}
                        >
                          Finalizar pedido
                        </Button>
                      </Col>
                    </RowDesktop>
                  </Col>
                  <Col xs={12} lg={5} className="order-0 order-lg-1">
                    <CartContainer style={{ padding: 0 }}>
                      <CardHeader>
                        <h4 className="card-title text-black">
                          <small className="small">Seu pedido em</small> <br />
                          {getSelectedStore().name}
                        </h4>
                      </CardHeader>
                      <div style={{ padding: '1.25rem' }}>
                        <CartBody
                          cart={cart}
                          showCupom
                          onDiscountToggle={() => {
                            if (cart.code) {
                              this.props.applyPromoCode({
                                code: null,
                                percent: 0,
                                promocodeId: null,
                              });
                            } else {
                              this.setState({
                                openDiscount: !this.state.openDiscount,
                              });
                            }
                          }}
                          validatingPromoCode={this.state.validatingPromoCode}
                          onValidatePromoCode={this.validateCupom}
                          onChangeDiscountCode={promoCode =>
                            this.setState({ promoCode })
                          }
                          removeAction={this.removeProduct}
                          openDiscount={this.state.openDiscount}
                          editAction={({ id }) =>
                            this.props.history.push(`/${id}/edit`)
                          }
                        />
                        <CartFooter
                          cart={cart}
                          discount={discount}
                          deliveryFee={this.state.deliveryFee}
                        />
                      </div>
                    </CartContainer>
                  </Col>
                </Row>
              )}
            </CardBody>
          )}
        </CartCheckout>
      </>
    );
  }
}
const mapStateToProps = state => {
  const {
    config: { config },
    cart: { cart },
  } = state;
  return { cart, config };
};
export default connect(mapStateToProps, {
  setFromCookie,
  clearCart,
  setConfigs,
  //  changeStore,
  applyPromoCode,
  removeFromCart,
})(Checkout);
