import {
  ADD_TO_CART,
  SUM_FROM_CART,
  SUB_FROM_CART,
  REPLACE_TO_CART,
  REMOVE_FROM_CART,
  LOGIN_USER,
  RETRIEVE_CART,
  CLEAR_CART,
  LOGOUT_USER,
  APPLY_PERCENT,
  ADD_REDEEM_ITEM,
  SET_CONFIG,
  SET_HASH,
  SET_CATEGORIES,
} from './actionTypes';

export const setHash = hash => ({
  type: SET_HASH,
  payload: { hash },
});
export const setCategories = categories => ({
  type: SET_CATEGORIES,
  payload: { categories },
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const setConfigs = ({ config }) => {
  return {
    type: SET_CONFIG,
    payload: {
      config,
    },
  };
};

export const applyPromoCode = ({
  percent,
  discountId,
  money,
  promocodeId,
  discountDescription,
}) => ({
  type: APPLY_PERCENT,
  percent,
  money,
  promocodeId,
  discountDescription,
  discountId,
});

export const addRedeemItem = redeem => ({
  type: ADD_REDEEM_ITEM,
  payload: {
    redeem,
  },
});

export const loginUser = user => ({
  type: LOGIN_USER,
  payload: {
    user,
  },
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const setFromCookie = cart => ({
  type: RETRIEVE_CART,
  payload: {
    cart,
  },
});
export const removeFromCart = cartId => ({
  type: REMOVE_FROM_CART,
  payload: {
    cartId,
  },
});

export const sumFromCart = cartId => ({
  type: SUM_FROM_CART,
  payload: {
    cartId,
  },
});
export const subFromCart = cartId => ({
  type: SUB_FROM_CART,
  payload: {
    cartId,
  },
});

export const addToCart = content => ({
  type: ADD_TO_CART,
  payload: {
    ...content,
  },
});

export const replaceToCart = content => ({
  type: REPLACE_TO_CART,
  payload: {
    ...content,
  },
});
