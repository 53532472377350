import styled from "styled-components";
import { IoMdMenu } from "react-icons/io";

export const Logo = styled.img`
  zoom: 0.6;
  cursor: pointer;
  max-height: 150px;
  @media (max-width: 1400px) {
    left: 25%;
    zoom: 0.8;
  }
  @media (max-width: 768px) {
    left: 25%;
    zoom: 0.5;
  }
`;

export const MobileMenu = styled(IoMdMenu)`
  display: none;
  position: absolute;
  right: 25px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const Menu = styled.div`
  display: block;
  position: absolute;
  font-size: calc(8px + 2vmin);
  right: 45px;
  @media (max-width: 768px) {
    display: none;
  }
`;
