import React, { Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  FormText,
  ModalFooter,
} from "reactstrap";
import { FaMinusCircle, FaPlusCircle, FaTicketAlt } from "react-icons/fa";
import { calcProductTotalValue } from "../../util";

import CartItem from "./item";
import { ProductCartLabel, ProductCartPrice } from "./style";
import Loading from "../loading";

const CartBody = ({
  cart,
  subtractProductFromCart,
  sumProductFromCart,
  deliveryFee,
  validatingPromoCode,
  openDiscount = false,
  onDiscountToggle,
  showCupom = false,
  onValidatePromoCode,
  onChangeDiscountCode,
  editAction,
  removeAction,
}) => (
  <Fragment>
    <Modal isOpen={openDiscount} size="sm" toggle={onDiscountToggle}>
      <form onSubmit={onValidatePromoCode}>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label>Insira seu código de desconto</Label>
                <Input
                  disabled={validatingPromoCode}
                  required
                  onChange={({ target: { value: code } }) =>
                    onChangeDiscountCode(code)
                  }
                  placeholder="Desconto"
                />
                <FormText>Ex.: TUDO20</FormText>
              </FormGroup>
            </Col>
          </Row>
          {validatingPromoCode && (
            <Row>
              <Col xs={12}>
                <Loading message="Validando código" />
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={validatingPromoCode}
            outline
            onClick={onDiscountToggle}
            color="danger"
          >
            Cancela
          </Button>
          <Button disabled={validatingPromoCode} type="submit">
            Validar código de desconto
          </Button>
        </ModalFooter>
      </form>
    </Modal>
    {cart.products.map((product) => (
      <Fragment key={`${product.cartId}`}>
        <Row>
          <Col xs={8} lg={6}>
            <Row>
              <Col xs={12}>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "0.8em",
                    fontWeight: "bold",
                    wordBreak: "initial",
                  }}
                >
                  {product.name}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={4} lg={6}>
            {!product.redeemId && (
              <Row>
                <Col>
                  {subtractProductFromCart && (
                    <FaMinusCircle
                      color="red"
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => subtractProductFromCart(product)}
                    />
                  )}
                  <span style={{ margin: "0.5em" }}>{product.quantity}</span>
                  {sumProductFromCart && (
                    <FaPlusCircle
                      color="green"
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => sumProductFromCart(product)}
                    />
                  )}
                </Col>
              </Row>
            )}
            {product.redeemId && !editAction && (
              <FaMinusCircle
                color="red"
                size={24}
                style={{ cursor: "pointer" }}
                onClick={() => subtractProductFromCart(product)}
              />
            )}
          </Col>
          {product.observation && product.observation !== "" && (
            <Col
              xs={12}
              style={{ fontSize: "0.7em", textAlign: "left" }}
            >{`* ${product.observation}`}</Col>
          )}
        </Row>
        <Row>
          <CartItem multiple={product.wizardSteps} />
          <Col xs={6}>
            <ProductCartLabel>Total unitário:</ProductCartLabel>
          </Col>
          <Col xs={6}>
            <ProductCartPrice>
              {calcProductTotalValue({
                cartProduct: { ...product },
                format: true,
                multiplyQuantity: false,
              })}
            </ProductCartPrice>
          </Col>
          <Col xs={6}>
            <ProductCartLabel>Total Item:</ProductCartLabel>
          </Col>
          <Col xs={6}>
            <ProductCartPrice>
              {calcProductTotalValue({
                cartProduct: { ...product },
                format: true,
                multiplyQuantity: true,
              })}
            </ProductCartPrice>
          </Col>
        </Row>
        
        <Row>
          <Col xs={6}>
            {product.price < 0 && (
              <ProductCartLabel>Valor do desconto:</ProductCartLabel>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 4 }}>
          {editAction && !product.redeemId && (
            <Col xs={4}>
              <Button outline onClick={() => editAction(product)}>
                Editar
              </Button>
            </Col>
          )}
          {removeAction && (
            <Col xs={8} style={{ textAlign: "left" }}>
              <Button
                color="danger"
                outline
                onClick={() => removeAction(product)}
              >
                Remover
              </Button>
            </Col>
          )}
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
      </Fragment>
    ))}
    {showCupom && (
      <Row style={{ marginTop: 4 }}>
        <Col xs={12}>
          <hr />
        </Col>
        <Col xs={12}>
          {!cart.discountDescription && (
            <Button outline onClick={onDiscountToggle}>
              <FaTicketAlt size={12} /> Aplicar Cupom de desconto
            </Button>
          )}
          {cart.discountDescription && (
            <Button outline onClick={onDiscountToggle}>
              <FaTicketAlt size={12} /> Remover Cupom de desconto{" "}
                {cart.discountDescription}
            </Button>
          )}
        </Col>
        <Col xs={12}>
          <hr />
        </Col>
      </Row>
    )}
  </Fragment>
);
export default CartBody;
