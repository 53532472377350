import React from 'react';
import {
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';

import { HintHeader, FullHeigthDiv, Footer } from './style';
import {
  toBlr,
  countSelectedOptions,
  calcProductTotalValue,
  getUser,
} from '../../util';
import CartResume from './resume';
import { Button } from '../button/style';
import { trackEvent } from '../../util/track';

const Step = ({
  step,
  simpleItem = false,
  wizardSteps,
  subtractOptional,
  sumOptional,
  moveNext,
  height,
  wizardIndex,
  moveBack,
  updateObservation,
  product,
  updateWizardSteps,
  subtractProduct,
  sumProduct,
}) => {
  if (!step || simpleItem) {
    trackEvent({
      name: 'openProductResume',
      data: {
        productId: product.id,
        productName: product.name,
        user: getUser(),
      },
    });

    return (
      <CartResume
        product={product}
        subtractProduct={subtractProduct}
        sumProduct={sumProduct}
        updateObservation={updateObservation}
        wizardSteps={wizardSteps}
      />
    );
  }
  const countSelectedOptionals = countSelectedOptions(step);
  trackEvent({
    name: 'navigateToStep',
    data: {
      step: step.title,
      user: getUser(),
    },
  });

  return (
    <>
      <ModalBody className="no-overflow">
        <Row>
          <Col xs={12}>
            <HintHeader>
              {step.min === 0 ? (
                `Escolha uma opção (min: ${step.min} max: ${step.max})`
              ) : (
                <b>
                  Obrigatório, máximo: {step.max}, mínimo: {step.min}
                </b>
              )}
            </HintHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ fontWeight: 'bold' }}>
            {step.title}
          </Col>
          <Col xs={12}>
            <h3>{step.description}</h3>
          </Col>
        </Row>
        <FullHeigthDiv
          height={height}
          style={{
            overflowY: 'scroll',
            zIndex: 400,
            overflowX: 'hidden',
            paddingBottom: 250,

            width: '100%',
          }}
        >
          {step.options
            .sort((a, b) => {
              if (a.position > b.position || a.name > b.name) {
                return 1;
              }
              if (a.position < b.position || a.name < b.name) {
                return -1;
              }
              return 0;
            })
            .map(option => {
              const { name, image, id, description, price, quantity } = option;
              return (
                <Row key={option.id}>
                  <Col xs={8}>
                    <Row style={{ padding: 2 }}>
                      <Col xs={12}>
                        {step.optionType === 'MULTIPLE' && (
                          <Row>
                            <Col>
                              <FaMinusCircle
                                color="red"
                                size={24}
                                style={{ cursor: 'pointer' }}
                                onClick={subtractOptional({
                                  option,
                                  step,
                                })}
                              />
                              <span style={{ margin: '0.5em' }}>
                                {quantity}
                              </span>
                              <FaPlusCircle
                                color="green"
                                size={24}
                                style={{ cursor: 'pointer' }}
                                onClick={sumOptional({ option, step })}
                              />
                              {` ${name}`}
                              {image && (
                                <img
                                  src={`https://d26aylt6qzro7d.cloudfront.net/${process.env.REACT_APP_MERCHANT_ID}/${image}`}
                                  alt={`Pizza ${name}`}
                                  width="50px"
                                  style={{ marginLeft: 10 }}
                                />
                              )}
                            </Col>
                          </Row>
                        )}
                        {step.optionType === 'SINGLE' && (
                          <FormGroup check>
                            <Label check>
                              <Input
                                checked={
                                  (step.formData[id] &&
                                    step.formData[id].checked) ||
                                  false
                                }
                                type="radio"
                                onChange={newValue => {}}
                                onClick={({ target: { checked, value } }) => {
                                  if (!step.formData[id]) {
                                    step.formData[id] = { checked: false };
                                  }
                                  step.formData[id].checked =
                                    !step.formData[id].checked;
                                  step.options
                                    .filter(option => option.id !== id)
                                    .forEach(item => {
                                      step.formData[item.id] = {
                                        checked: false,
                                      };
                                    });
                                  const wizardStepsToState = wizardSteps.map(
                                    stepWizard => {
                                      if (step.id !== stepWizard.id)
                                        return stepWizard;
                                      return {
                                        ...stepWizard,
                                        options: stepWizard.options.map(
                                          optionStep => {
                                            if (optionStep.id !== option.id)
                                              return optionStep;
                                            return {
                                              ...optionStep,
                                              quantity: null,
                                            };
                                          }
                                        ),
                                      };
                                    }
                                  );
                                  updateWizardSteps(wizardStepsToState);
                                }}
                              />
                              {name}
                            </Label>
                            {image && (
                              <img
                                src={`https://d26aylt6qzro7d.cloudfront.net/${process.env.REACT_APP_MERCHANT_ID}/${image}`}
                                alt={`Pizza ${name}`}
                                width="50px"
                                style={{ marginLeft: 10 }}
                              />
                            )}
                          </FormGroup>
                        )}
                      </Col>
                      <Col xs={12} style={{ fontWeight: 'lighter' }}>
                        {description}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={4} style={{ textAlign: 'left', size: '0.2em' }}>
                    {price > 0 ? toBlr(price) : ''}
                  </Col>
                </Row>
              );
            })}
        </FullHeigthDiv>
      </ModalBody>
      <Footer>
        <div style={{ width: '30%' }}>
          <Row>
            <Col sm={12}>Total item:</Col>
            <Col sm={12}>
              {calcProductTotalValue({
                cartProduct: {
                  ...product,
                  wizardSteps,
                },
                format: true,
              })}
            </Col>
          </Row>
        </div>
        <div style={{ textAlign: 'right' }}>
          {wizardIndex > 0 && (
            <Button outline onClick={moveBack} style={{ marginRight: 4 }}>
              {'<<'} Voltar
            </Button>
          )}
          <Button
            disabled={step.min > 0 && countSelectedOptionals < step.min}
            onClick={moveNext}
          >
            Avançar {'>>'}
          </Button>
          {step.min > 0 && countSelectedOptionals < step.min && (
            <div>
              <FormText color="red">
                Você precisa selecionar ao menos um item para continuar
              </FormText>
            </div>
          )}
        </div>
      </Footer>
    </>
  );
};
export default Step;
