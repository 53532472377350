const BASE_URL = 'https://api-ecommerce.opdv.com.br';
// const BASE_URL = "http://107.23.71.202:5111/api-ecommerce";
// const BASE_URL = 'http://ec2-54-235-113-153.compute-1.amazonaws.com:8082';
// const BASE_URL = 'http://localhost:6003';

let catalog = [];

export const checkPromocode = ({ code, token, storeId, orderValue }) => {
  return fetch(
    `${BASE_URL}/loyalty/promocodes/${code}/validate/${storeId}/${orderValue}`,
    {
      headers: {
        'x-whats-token': process.env.REACT_APP_TOKEN,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  ).then(res => res.json());
};

export const valideteHashcode = hash => {
  return fetch(`${BASE_URL}/hash/process/${hash}`, {
    headers: {
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
  }).then(res => res.json());
};

export const retrieveCampains = async storeId => {
  return fetch(`${BASE_URL}/campaigns/${storeId}`, {
    headers: {
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
  }).then(res => res.json());
};
const retrieveCatalog = async storeId => {
  return fetch(`${BASE_URL}/catalog?storeId=${storeId}`, {
    headers: {
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
  }).then(res => res.json());
};

export const listCategories = async storeId => {
  catalog = await retrieveCatalog(storeId);
  return catalog.map(catalogItem => ({
    name: catalogItem.name,
    id: catalogItem.id,
    image: catalogItem.image,
    siteColor: catalogItem.siteColor,
    categories: catalogItem.categories,
    items: catalogItem.items,
  }));
};

export const listProducts = (parentCategortId, categoryId) => {
  const products = catalog
    .find(({ id }) => id === parentCategortId)
    .categories.filter(({ id }) => id === categoryId);
  let allProducts = [];
  products.forEach(product => {
    allProducts = [...allProducts, ...product.items];
  });
  return allProducts || [];
};

export const listAllProducts = async () => {
  const catalog = await retrieveCatalog();
  let allProducts = [];
  catalog.forEach(({ categories }) => {
    categories.forEach(category => {
      allProducts = [...allProducts, ...category.items];
    });
  });
  return allProducts || [];
};

export const fetchStore = (storeId, hoursAfterShceduled = 0) =>
  fetch(`${BASE_URL}/stores/${storeId}`, {
    headers: {
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
  })
    .then(res => res.json())
    .then(store => {
      return {
        ...store,
        open:
          store.open ||
          (hoursAfterShceduled && parseInt(hoursAfterShceduled) > 0),
      };
    });

export const fetchStores = (hoursAfterShceduled = 0) => {
  const filterStoreIds = process.env.REACT_APP_FILTER_STORE_IDS;
  return fetch(
    `${BASE_URL}/v2/stores${
      filterStoreIds ? `?filter=[${filterStoreIds}]` : ''
    }`,
    {
      headers: {
        'x-whats-token': process.env.REACT_APP_TOKEN,
      },
    }
  )
    .then(res => res.json())
    .then(stores =>
      stores.map(store => {
        return {
          ...store,
          open:
            store.open ||
            (hoursAfterShceduled && parseInt(hoursAfterShceduled) > 0),
        };
      })
    );
};

export const getColors = () => {
  return fetch(
    `${BASE_URL}/v2/config/colors?hostname=${
      process.env.REACT_APP_URL || process.env.PUBLIC_URL
    }`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-whats-token': process.env.REACT_APP_TOKEN,
      },
    }
  ).then(res => res.json());
};

export const saveFastCustomer = customer => {
  return fetch(`${BASE_URL}/v2/customer/fast`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
    body: JSON.stringify(customer),
  }).then(res => res.json());
};

export const callLogin = ({ username, password }) => {
  return fetch(`${BASE_URL}/v2/customer/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
    body: JSON.stringify({ username, password }),
  }).then(res => res.json());
};

export const createNewAddress = ({ body, token }) => {
  return fetch(`${BASE_URL}/v2/customer/address`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const fetchUserDetail = ({ token }) => {
  return fetch(`${BASE_URL}/customer/me`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};

export const fetchUserData = ({ cpf = '', email = '' }) =>
  fetch(`${BASE_URL}/customers?cpf=${cpf}&email=${email}`, {
    headers: {
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
  }).then(res => res.json());

export const fetchAddressInformation = cep =>
  fetch(`${BASE_URL}/deliveryareas/zip/${cep}/address`, {
    headers: {
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
  }).then(res => res.json());

export const fetchDeliveryInformationV2 = ({
  zip,
  number,
  street,
  hoursAfterShceduled = 0,
  merchantId,
}) => {
  const filterStoreIds = process.env.REACT_APP_FILTER_STORE_IDS;
  return fetch(
    `${BASE_URL}/v2/coverage/${zip}?number=${number}&street=${encodeURIComponent(
      street
    )}${filterStoreIds ? `&filter=[${filterStoreIds}]` : ''}`,
    {
      method: 'POST',
      body: JSON.stringify({ merchantId }),
      headers: {
        'x-whats-token': process.env.REACT_APP_TOKEN,
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => res.json())
    .then(stores =>
      stores.map(store => {
        return {
          ...store,
          open:
            store.open ||
            (hoursAfterShceduled && parseInt(hoursAfterShceduled) > 0),
        };
      })
    );
};

export const fetchDeliveryInformation = cep =>
  fetch(`${BASE_URL}/deliveryareas/zip/${cep}/validadecoverage`, {
    headers: {
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
  }).then(res => res.json());

export const fetchCards = (token, storeId, hasLogin) => {
  if (hasLogin === 'NO') return { cards: [] };
  return fetch(`${BASE_URL}/cards/${storeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};

export const createCard = (token, body) => {
  return fetch(`${BASE_URL}/cards`, {
    method: 'POST',
    headers: {
      'x-whats-token': process.env.REACT_APP_TOKEN,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then(res => res.json())
    .then(res => {
      console.log('card created: ', res);
      return res;
    })
    .catch(e => console.log('erro ao criar cartão: ', e));
};
export const fetchStorePayments = storeId =>
  fetch(`${BASE_URL}/payments/store/${storeId}/payments`, {
    headers: {
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
  }).then(res => res.json());

export const sendMetadata = (orderId, body) => {
  return fetch(`${BASE_URL}/metadata/${orderId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
    body: JSON.stringify(body),
  }).then(res => res.json());
};
export const sendOrder = (storeId, body, token, hasLogin) => {
  return fetch(`${BASE_URL}/stores/${storeId}/orders`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
      Authorization: hasLogin === 'NO' ? '' : `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const createAccount = body => {
  return fetch(`${BASE_URL}/customer/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const verifyAccount = body => {
  return fetch(`${BASE_URL}/customer/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const resetVerifyAccount = body => {
  return fetch(`${BASE_URL}/customer/reset/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const resetAccount = body => {
  return fetch(`${BASE_URL}/customer/reset`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
    body: JSON.stringify(body),
  }).then(res => res.json());
};

export const fetchOrders = token => {
  return fetch(`${BASE_URL}/v2/customer/orders`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};

export const fetchTempOrders = ids => {
  return fetch(`${BASE_URL}/v2/customer/order/${ids}`, {
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
  }).then(res => res.json());
};

export const doSendSmsAgain = ({ cpf, validateToken }) => {
  return fetch(`${BASE_URL}/v2/customer/reset/aws`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-whats-token': process.env.REACT_APP_TOKEN,
    },
    body: JSON.stringify({ cpf, validateToken }),
  }).then(res => res.json());
};

export const fetchRedeemOptions = ({ token, storeId }) => {
  return fetch(
    `${BASE_URL}/v2/loyaltypoints/redeemOptions?storeId=${storeId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(res => res.json());
};

export const fetchOpenHours = ({ token, storeId, hasLogin }) => {
  return fetch(`${BASE_URL}/stores/${storeId}/openhours`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: hasLogin === 'NO' ? '' : `Bearer ${token}`,
      'x-whats-token': hasLogin === 'NO' ? token : '',
    },
  }).then(res => res.json());
};
