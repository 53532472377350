import React from "react";
import { ModalBody, Row, Col, Input } from "reactstrap";
import { calcProductTotalValue, getMinPrice } from "../../util";
import { ProductName, MoneyLabel } from "./style";
import CartItem from "./item";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { config } from "../../config";

const CartResume = ({
  product,
  wizardSteps,
  startStep,
  updateObservation,
  subtractProduct,
  sumProduct,
}) => (
  <ModalBody>
    <Row>
      {product.image && (
        <Col xs={12} style={{ textAlign: "center" }}>
          <img
            style={{ width: "100%", height: "auto", maxWidth: 400 }}
            src={`https://d26aylt6qzro7d.cloudfront.net/${config.id}/${product.image}`}
            alt={`produto do sistema o pdv da empresa OPDV`}
          />
        </Col>
      )}
    </Row>
    <Row>
      <Col lg={8} xs={8}>
        <Row>
          <Col xs={12}>
            <ProductName>{product.name}</ProductName>
            <br />
            {product.description}
          </Col>

          <CartItem multiple={wizardSteps} />
        </Row>
      </Col>
      <Col lg={4} xs={4} style={{ textAlign: "right" }}>
        {!startStep && (
          <Row>
            <Col>
              <FaMinusCircle
                color="red"
                size={24}
                style={{ cursor: "pointer" }}
                onClick={subtractProduct}
              />
              <span style={{ margin: "0.5em" }}>{product.quantity || 0}</span>
              {!product.redeemId && (
                <FaPlusCircle
                  color="green"
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={sumProduct}
                />
              )}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
    <Row style={{ marginTop: "1.5em" }}>
      <Col xs={12}>
        <Row>
          <Col
            lg={10}
            xs={8}
            style={{
              fontSize: "0.9em",
              color: "#717171",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {product.price
              ? <span>Valor unitário</span> : <span>A partir de</span>
            }
          </Col>
          <Col xs={4} lg={2} style={{ textAlign: "right" }}>
            <MoneyLabel>
              {startStep 
                ? getMinPrice(product)
                : calcProductTotalValue({
                    cartProduct: { ...product, wizardSteps },
                    format: true,
                  })
              }
            </MoneyLabel>
          </Col>
        </Row>
        {!startStep && (
          <Row>
            <Col
              lg={10}
              xs={8}
              style={{
                fontSize: "0.9em",
                textAlign: "right",
                color: "#717171",
              }}
            >
              Valor total
            </Col>
            <Col xs={4} lg={2} style={{ textAlign: "right" }}>
              <MoneyLabel>
                {calcProductTotalValue({
                  cartProduct: { ...product, wizardSteps },
                  multiplyQuantity: true,
                  format: true,
                })}
              </MoneyLabel>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
    {!startStep && (
      <Row style={{ marginTop: "1.5em" }}>
        <Col xs={12}>
          <Input
            placeholder="Observações"
            style={{ width: "100%" }}
            value={product.observation}
            onChange={({ target: { value: observation } }) =>
              updateObservation({ ...product, observation })
            }
          />
        </Col>
      </Row>
    )}
  </ModalBody>
);

export default CartResume;
