import styled from "styled-components";
import { Modal } from "reactstrap";

export const Footer = styled.div`
  padding: 0.75rem !important;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 0.9rem !important;
  }
`;
export const CartModal = styled(Modal)`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`;
export const VariationName = styled.span`
  font-size: 1em;
  text-align: left;
  @media (max-width: 768px) {
    font-size: calc(7px + 1.5vmin);
  }
`;

export const OptionalName = styled.span`
  font-size: calc(2px + 1.3vmin);
  text-align: left;
  float: left;
  @media (max-width: 768px) {
    font-size: calc(7px + 1.3vmin);
  }
`;

export const ProductCartLabel = styled.span`
  font-size: calc(6px + 1vmin);
  float: left;
  @media (max-width: 768px) {
    font-size: calc(9px + 1.5vmin);
  }
`;

export const ProductCartPrice = styled.span`
  font-size: calc(6px + 1vmin);
  text-align: right;
  float: right;
  @media (max-width: 768px) {
    font-size: calc(9px + 1.5vmin);
  }
`;

export const ProductPrice = styled.span`
  font-size: calc(7px + 1.3vmin);
  text-align: right;
  float: right;
  @media (max-width: 768px) {
    font-size: calc(9px + 1.5vmin);
  }
`;

export const ProductName = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  @media (max-width: 768px) {
    font-size: calc(8px + 1.5vmin);
  }
`;

export const HintHeader = styled.span`
  color: #9a9a;
  font-size: 1em;
  text-align: left;
  margin: 0.5em;
`;

export const MoneyLabel = styled.span`
  font-size: 1.2em;
  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

export const FullHeigthDiv = styled.div`
  max-height: ${(props) => props.height * 0.6}px !important;
  
`;
