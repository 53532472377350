import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  CardFooter,
  Modal,
  ModalBody,
  ModalFooter,
  CardHeader,
  ModalHeader,
  FormText,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import { Input } from '../../components/input';
import { Button } from '../../components/button/style';

import { CreateForgotPasswordCard } from './style';
import {
  fetchUserDetail,
  resetAccount,
  resetVerifyAccount,
  doSendSmsAgain,
} from '../../service';
import { storeUser } from '../../util';
import { loginUser } from '../../redux/actions';

class Forgot extends Component {
  state = {
    loading: false,
    email: null,
    password: '',
    cpf: '',
    timeToStart: 30,
    code: '',
  };

  componentDidMount() {
    this.interval = null;
  }

  startTimer = () => {
    if (this.interval !== null) return;

    this.setState(
      { timeToStart: 30 },
      () =>
        (this.interval = setInterval(() => {
          const { timeToStart } = this.state;
          if (timeToStart - 1 >= 0)
            this.setState({ timeToStart: timeToStart - 1 });
          else {
            clearInterval(this.interval);
            this.interval = null;
          }
        }, 1000))
    );
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  resetPassword = async e => {
    e.preventDefault();
    try {
      this.setState({ loading: true });
      const { phone, email } = await resetAccount({
        cpf: this.state.cpf.replace(/\D/g, ''),
      });
      if (!phone) {
        this.setState({
          codeCheck: false,
          loading: false,
          phone,
          code: '',
          password: '',
        });
        alert('Nenhum usuário localizado com este CPF.');
        return;
      }
      this.setState({
        codeCheck: true,
        loading: false,
        email,
        phone,
        code: '',
        password: '',
      });
      this.startTimer();
    } catch (e) {
      console.log('e: ', e);
      this.setState({ loading: false });
    }
  };

  sendAgain = async () => {
    this.startTimer();
    const { cpf } = this.state;
    await doSendSmsAgain({ cpf: cpf.replace(/\D/g, '') });
  };

  verifyCode = async e => {
    e.preventDefault();
    const { code, cpf, password } = this.state;

    try {
      this.setState({ loading: true });
      const verified = await resetVerifyAccount({ code, cpf, password });
      if (verified.error) {
        this.setState({ loading: false });
        alert(verified.error);
        return;
      }
      const completedUser = await fetchUserDetail({ token: verified.token });

      storeUser({ ...completedUser, token: verified.token });
      this.props.loginUser(completedUser);
      this.props.history.push(`/${process.env.REACT_APP_PROJECT}`);
    } catch (e) {
      console.log('e::', e);
    }
    this.setState({ loading: false });
  };

  render() {
    const { loading, timeToStart, codeCheck, phone, email } = this.state;
    if (!this.props.config) {
      return null;
    }
    return (
      <div>
        <Modal size="lg" isOpen={codeCheck}>
          <form onSubmit={this.verifyCode}>
            <ModalHeader>Verificar Código</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <span>Código enviado para: {phone}</span>
                </Col>
                {email && email !== '' && (
                  <Col xs={12}>
                    <span>Seu e-mail de login:{email}</span>
                  </Col>
                )}
              </Row>
              <Row>
                <Col sm={12} lg={6} style={{ color: 'black' }}>
                  <FormGroup>
                    <Label>Código:</Label>
                    <Input
                      placeholder="XXXXXXX"
                      required
                      maxLength={6}
                      type="number"
                      value={this.state.code}
                      onChange={({ target: { value } }) =>
                        this.setState({ code: value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6} style={{ color: 'black' }}>
                  <FormGroup>
                    <Label>Nova senha:</Label>
                    <Input
                      required
                      type="password"
                      value={this.state.password}
                      onChange={({ target: { value } }) =>
                        this.setState({ password: value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col xs={12}>
                  {timeToStart !== 0 && (
                    <Button
                      outline
                      color="primary"
                      type="button"
                      disabled={timeToStart !== 0}
                    >
                      Reenviar SMS em: 00:{`${timeToStart}`.padStart(2, 0)}
                    </Button>
                  )}
                  {timeToStart === 0 && (
                    <Button
                      outline
                      onClick={this.sendAgain}
                      color="primary"
                      disabled={timeToStart !== 0}
                    >
                      Reenviar SMS
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button
                    color="danger"
                    disabled={loading}
                    outline
                    onClick={() => this.setState({ codeCheck: false })}
                    style={{
                      appearance: 'initial',
                      WebkitAppearance: 'initial',
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={loading}
                    style={{
                      marginLeft: 8,
                      appearance: 'initial',
                      WebkitAppearance: 'initial',
                    }}
                  >
                    {loading ? 'Verificando dados..' : 'Verificar Código'}
                  </Button>
                  <FormText>
                    Dependendo da operadora, o SMS pode demorar um pouco para
                    chegar.
                  </FormText>
                </Col>
              </Row>
            </ModalFooter>
          </form>
        </Modal>
        <form onSubmit={this.resetPassword}>
          <CreateForgotPasswordCard>
            <CardHeader>Resgatar Senha</CardHeader>
            <CardBody>
              <Row style={{ justifyContent: 'center' }}>
                <Col xs={6} style={{ color: 'black' }}>
                  <Input
                    Component={InputMask}
                    label="CPF"
                    mask="999.999.999-99"
                    value={this.state.cpf}
                    required
                    onChange={({ target: { value } }) =>
                      this.setState({ cpf: value })
                    }
                  />
                </Col>
              </Row>
              <Row style={{ justifyContent: 'center' }}>
                <Button
                  color="primary"
                  type="submit"
                  disabled={loading}
                  style={{
                    appearance: 'initial',
                    WebkitAppearance: 'initial',
                    fontSize: 12,
                    width: '25%',
                  }}
                >
                  {loading ? 'Solicitando..' : 'Continuar'}
                </Button>
              </Row>
            </CardBody>
            <CardFooter>
              <Button
                outline
                style={{ fontSize: 12, border: 0 }}
                onClick={() =>
                  this.props.history.push(
                    `/${process.env.REACT_APP_PROJECT}/login`
                  )
                }
              >
                Voltar para o login
              </Button>
            </CardFooter>
          </CreateForgotPasswordCard>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};

export default connect(mapStateToProps, {
  loginUser,
})(Forgot);
