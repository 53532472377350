/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { Button as ButtonReact } from 'reactstrap';
import { connect } from 'react-redux';
import { transparentize } from 'polished';

const ButtonBase = styled(ButtonReact)`
  color: ${props =>
    props.outline
      ? props.config.bottomBackgroundColor
      : props.config.bottomTextColor};
  background-color: ${props =>
    props.outline ? 'transparent' : props.config.bottomBackgroundColor};
  border-color: ${props => props.config?.bottomBackgroundColor} !important;
  transition: 0.3s;
  :hover {
    opacity: 0.8;
    color: ${props => (props.config ? props.config.bottomTextColor : '')};
    background-color: ${props =>
      props.config ? props.config.bottomBackgroundColor : ''};
  }
  :active,
  :focus {
    color: ${props =>
      props.config ? props.config.bottomTextColor : ''} !important;
    background-color: ${props =>
      props.config ? props.config.bottomBackgroundColor : ''} !important;
    border-color: ${props =>
      props.config ? props.config.bottomBackgroundColor : ''} !important;
    box-shadow: 0 0 0 0.2rem
      ${props =>
        props.config && props.config.bottomBackgroundColor
          ? transparentize(0.5, props.config.bottomBackgroundColor)
          : '#3190f080'} !important;
  }
  &.disabled {
    color: ${props =>
      props.outline
        ? props.config.bottomBackgroundColor
        : props.config.bottomTextColor};
    background-color: ${props =>
      props.outline ? 'transparent' : props.config.bottomBackgroundColor};
    pointer-events: none;
  }
  &.disabled:hover {
    color: ${props =>
      props.outline
        ? props.config.bottomBackgroundColor
        : props.config.bottomTextColor};
    background-color: ${props =>
      props.outline ? 'transparent' : props.config.bottomBackgroundColor};
  }
  @media (max-width: 768px) {
    display: ${props => (props.hideMobile ? 'none' : '')};
  }
`;

const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};
export const Button = connect(mapStateToProps, {})(ButtonBase);
