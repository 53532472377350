import React, { Component } from "react";
import { Card, CardBody, Row, Col, CardFooter, Button } from "reactstrap";

class Success extends Component {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Card>
        <CardBody>
          <Row>
            <Col xs={12}>
              <h2>Pedido #{id} realizado com sucesso!!</h2>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button onClick={() => this.props.history.push(`/${process.env.REACT_APP_PROJECT}/orders`)}>
            Acompanhar pedido
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

export default Success;
