import moment from "moment";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

import {
  ADD_TO_CART,
  SUB_FROM_CART,
  SUM_FROM_CART,
  REMOVE_FROM_CART,
  REPLACE_TO_CART,
  APPLY_PERCENT,
  RETRIEVE_CART,
  ADD_REDEEM_ITEM,
  CLEAR_CART,
} from "../actionTypes";
import { storeCart, clearCart, calcTotalCart } from "../../util";

const initialState = {
  cart: {
    products: [],
    discount: 0,
    discountDescription: null,
    percent: 0,
    promocodeId: null,
  },
};

const sortCartProducts = (a, b) => b.timeCreation - a.timeCreation;

export default function (state = initialState, action) {
  switch (action.type) {
    case APPLY_PERCENT: {
      const { percent, money, promocodeId, discountDescription } = action;
      const cartBase = {
        ...state.cart,
        discountDescription: null,
        discount: null,
        promocodeId: null,
        discountId: null,
      };
      cartBase.total = calcTotalCart({
        cartProducts: cartBase.products,
        deliveryFee: cartBase.deliveryFee,
        format: false,
      });
      const newState = {
        cart: {
          ...cartBase,
          discount: !money
            ? calcTotalCart({
                cartProducts: cartBase.products,
                deliveryFee: cartBase.deliveryFee,
                format: false,
              }) * percent
            : money,
          discountDescription,
          promocodeId,
          percent,
        },
      };
      storeCart(newState.cart);
      return newState;
    }
    case CLEAR_CART: {
      clearCart();
      return {
        ...initialState,
      };
    }
    case RETRIEVE_CART: {
      const { cart } = action.payload;
      const cartBase = {
        ...cart,
        discountDescription: null,
        discount: null,
        promocodeId: null,
        discountId: null,
      };
      cartBase.total = calcTotalCart({
        cartProducts: cartBase.products,
        deliveryFee: cartBase.deliveryFee,
        format: false,
      });
      return {
        ...state,
        cart: {
          ...cartBase,
        },
      };
    }
    case REMOVE_FROM_CART: {
      const { cartId } = action.payload;
      const cartBase = {
        ...state.cart,
        percent: 0,
        discount: 0,
        discountDescription: "",
        products: state.cart.products
          .filter((product) => product.cartId !== cartId)
          .sort(sortCartProducts),
      };
      cartBase.total = calcTotalCart({
        cartProducts: cartBase.products,
        deliveryFee: cartBase.deliveryFee,
        format: false,
      });
      const newState = {
        ...state,
        cart: {
          ...cartBase,
        },
      };
      storeCart(newState.cart);
      return {
        ...newState,
        discountDescription: "",
        percent: 0,
        discount: 0,
      };
    }
    case SUB_FROM_CART: {
      const { cartId } = action.payload;
      const cartBase = {
        ...state.cart,
        products: state.cart.products
          .map((product) => {
            return {
              ...product,
              quantity:
                product.cartId === cartId
                  ? product.quantity - 1
                  : product.quantity,
            };
          })
          .sort(sortCartProducts),
      };
      cartBase.total = calcTotalCart({
        cartProducts: cartBase.products,
        deliveryFee: cartBase.deliveryFee,
        format: false,
      });
      if (cartBase.total < 0) {
        cartBase.products = cartBase.products.filter(
          ({ redeemId, price }) => (redeemId && price === 0) || !redeemId
        );
        cartBase.total = calcTotalCart({
          cartProducts: cartBase.products,
          deliveryFee: cartBase.deliveryFee,
          format: false,
        });
      }
      const newState = {
        ...state,
        cart: {
          ...cartBase,
        },
      };
      storeCart(newState.cart);
      return {
        ...newState,
        discount: 0,
        percent: 0,
        discountDescription: "",
      };
    }
    case SUM_FROM_CART: {
      const { cartId } = action.payload;
      const cartBase = {
        ...state.cart,
        products: state.cart.products
          .map((product) => {
            return {
              ...product,
              quantity:
                product.cartId === cartId
                  ? product.quantity + 1
                  : product.quantity,
            };
          })
          .sort(sortCartProducts),
      };
      cartBase.total = calcTotalCart({
        cartProducts: cartBase.products,
        deliveryFee: cartBase.deliveryFee,
        format: false,
      });
      const newState = {
        ...state,
        cart: {
          ...cartBase,
        },
      };
      storeCart(newState.cart);
      return {
        ...newState,
        discount: 0,
        percent: 0,
        discountDescription: "",
      };
    }
    case REPLACE_TO_CART: {
      const product = action.payload;
      const productIndex = state.cart.products.findIndex(
        ({ cartId }) => cartId === product.cartId
      );
      const productsToCart = [...state.cart.products];
      productsToCart[productIndex] = product;

      const newState = {
        ...state,
        cart: {
          ...state.cart,
          products: [...productsToCart],
        },
      };
      storeCart(newState.cart);
      return {
        ...newState,
        discount: 0,
        percent: 0,
        discountDescription: "",
      };
    }
    case ADD_REDEEM_ITEM: {
      const { redeem } = action.payload;
      const redeemProduct = {
        name: redeem.discountName || redeem.productName,
        points: redeem.pointsToRedeem,
        quantity: 1,
        redeemId: redeem.id,
        wizardSteps: [],
        cartId: `${moment().format("YYYYMMDDHHmmssSSSSSSSSS'")}`,
        timeCreation: moment().valueOf(),
        price: redeem.discountId ? parseInt(redeem.discountValue) * -1 : 0,
      };
      const cartBase = {
        ...state.cart,
        products: [
          ...state.cart.products,
          {
            ...redeemProduct,
          },
        ].sort(sortCartProducts),
      };
      const newState = {
        ...state,
        cart: {
          ...cartBase,
          total: calcTotalCart({
            cartProducts: cartBase.products,
            deliveryFee: cartBase.deliveryFee,
            format: false,
          }),
        },
      };
      storeCart(newState.cart);
      return {
        ...newState,
      };
    }
    case ADD_TO_CART: {
      toast.success("Produto adicionado!", {
        position: isMobile
          ? toast.POSITION.BOTTOM_CENTER
          : toast.POSITION.TOP_RIGHT,
      });
      const product = action.payload;
      const cartBase = {
        ...state.cart,
        products: [
          ...state.cart.products,
          {
            cartId: `${moment().format("YYYYMMDDHHmmssSSSSSSSSS'")}`,
            timeCreation: moment().valueOf(),
            ...product,
          },
        ].sort(sortCartProducts),
      };
      cartBase.total = calcTotalCart({
        cartProducts: cartBase.products,
        deliveryFee: cartBase.deliveryFee,
        format: false,
      });
      const newState = {
        ...state,
        cart: {
          ...cartBase,
        },
      };
      storeCart(newState.cart);
      return {
        ...newState,
        discount: 0,
        discountDescription: "",
        percent: 0,
      };
    }

    default:
      return state;
  }
}
