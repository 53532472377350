/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goToTop } from 'react-scrollable-anchor';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { Logo, MobileMenu, Menu } from './style';
import { CloseIcon } from '../Categories/style';
import { getUser, logout } from '../../util';
import { fetchUserDetail, getColors } from '../../service';
import { logoutUser, loginUser, setConfigs } from '../../redux/actions';

class Header extends Component {
  state = {
    drawerModal: false,
    customer: null,
  };

  async componentDidMount() {
    await this.loadUser();
    try {
      const colors = await getColors();
      this.props.setConfigs({ config: { ...colors } });
    } catch (e) {
      alert(`erro ao pegar configurações: ${e}`);
    }
  }

  loadUser = async () => {
    const user = getUser();
    if (!user) {
      return;
    }
    this.setState({ loading: true });
    const customer = await fetchUserDetail({ token: user.token });
    this.props.loginUser(customer);
    this.setState({ customer, loading: false });
  };

  logoutFromHeader = () => {
    logout();
    this.props.logoutUser();
    this.setState({ drawerModal: false, customer: null });
    this.props.history.push(`/${process.env.REACT_APP_PROJECT}`);
  };

  loginUser = () => {
    this.setState({ drawerModal: false, customer: null });
    this.props.history.push(`/${process.env.REACT_APP_PROJECT}/login`);
  };

  goToOrders = () => {
    this.setState({ drawerModal: false, customer: null });
    this.props.history.push(`/${process.env.REACT_APP_PROJECT}/orders`);
  };

  render() {
    const { user: customer, config } = this.props;
    if (!config) {
      return <div />;
    }
    return (
      <>
        <header
          style={{
            display: 'flex',
            color: 'white',
            height: 120,
            padding: 20,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 12,
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            borderBottom: `1px solid ${this.props.config.headerBottomColor}`,
            backgroundColor: this.props.config.headerBackgroundColor,
          }}
        >
          <Logo
            src={config.merchantLogo}
            onClick={() => {
              const baseElement = window.document.getElementById(
                'categoryContainer'
              );
              if (baseElement) {
                baseElement.scrollTo(0, 0);
                goToTop();
              }
              this.props.history.push(`/${process.env.REACT_APP_PROJECT}`);
            }}
          />
          <MobileMenu
            size={30}
            color={this.props.config.textBaseColor}
            onClick={() =>
              this.setState(
                { drawerModal: !this.state.drawerModal },
                this.loadUser
              )
            }
          />
          {(customer || config.hasLogin === 'NO') && (
            <Menu style={{ color: this.props.config.textBaseColor }}>
              <span
                onClick={this.goToOrders}
                style={{ cursor: 'pointer', fontSize: 16, marginRight: 60 }}
              >
                Meus Pedidos
              </span>
            </Menu>
          )}
          {config.hasLogin === 'YES' && (
            <Menu style={{ color: this.props.config.textBaseColor }}>
              <span
                onClick={!customer ? this.loginUser : this.logoutFromHeader}
                style={{ cursor: 'pointer', fontSize: 16 }}
              >
                {!customer ? 'Entrar' : 'Sair'}
              </span>
            </Menu>
          )}
        </header>
        <Modal
          size="lg"
          isOpen={this.state.drawerModal}
          toggle={() => this.setState({ drawerModal: false })}
        >
          <ModalHeader>
            OPDV
            <CloseIcon onClick={() => this.setState({ drawerModal: false })}>
              Fechar
            </CloseIcon>
          </ModalHeader>
          <ModalBody>
            <ul>
              {config.hasLogin === 'YES' && (
                <li>
                  <a
                    onClick={!customer ? this.loginUser : this.logoutFromHeader}
                  >
                    {!customer ? 'Entrar' : 'Sair'}
                  </a>
                </li>
              )}
              {(customer || config.hasLogin === 'NO') && (
                <li>
                  <a onClick={this.goToOrders}> Meus Pedidos</a>
                </li>
              )}
            </ul>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => {
  const {
    config: { config },
    user: { user },
  } = state;
  return { user, config };
};
export default connect(mapStateToProps, { logoutUser, loginUser, setConfigs })(
  Header
);
