import React from "react";
import { CardFooter, Row, Col } from "reactstrap";
import { ProductPrice } from "./style";
import { calcTotalCart, toBlr } from "../../util";

const CartFooter = ({
  cart,
  productTotal = 0,
  cartTotal = 0,
  discountTotal = 0,
  deliveryFee,
}) => {
  return (
    <CardFooter>
      {(deliveryFee || (cart && cart.discount !== null)) && (
        <Row>
          <Col xs={6}>
            <ProductPrice>Total Itens: </ProductPrice>
          </Col>
          <Col xs={6} style={{ textAlign: "right" }}>
            {productTotal > 0 && (
              <ProductPrice>{toBlr(productTotal)}</ProductPrice>
            )}
            {cart && (
              <ProductPrice>
                {calcTotalCart({
                  cartProducts: [...cart.products],
                })}
              </ProductPrice>
            )}
          </Col>
        </Row>
      )}
      {deliveryFee > 0 && (
        <Row>
          <Col xs={6}>
            <ProductPrice>Frete: </ProductPrice>
          </Col>
          <Col xs={6} style={{ textAlign: "right" }}>
            <ProductPrice>{toBlr(deliveryFee)}</ProductPrice>
          </Col>
        </Row>
      )}
      {discountTotal !== null && discountTotal > 0 && (
        <Row>
          <Col xs={6}>
            <ProductPrice>Desconto: </ProductPrice>
          </Col>
          <Col xs={6} style={{ textAlign: "right" }}>
            <ProductPrice>{toBlr(discountTotal)}</ProductPrice>
          </Col>
        </Row>
      )}
      {cart && (
        <Row>
          <Col xs={6}>
            <ProductPrice>Desconto: </ProductPrice>
          </Col>
          <Col xs={6} style={{ textAlign: "right" }}>
            <ProductPrice>{toBlr(cart.discount)}</ProductPrice>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={6}>
          <ProductPrice>Total: </ProductPrice>
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          {cartTotal > 0 && <ProductPrice>{toBlr(cartTotal)}</ProductPrice>}
          {cart && (
            <ProductPrice>
              {cartTotal ||
                calcTotalCart({
                  cartProducts: [...cart.products],
                  deliveryFee,
                  discount: cart.discount,
                })}
            </ProductPrice>
          )}
        </Col>
      </Row>
    </CardFooter>
  );
};
export default CartFooter;
