import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { config } from '../../config';
import {
  ProductDescriptionList,
  ProductName,
  ProductPrice,
  ProductBox,
} from './style';
import { getMinPrice } from '../../util';

const ProductBase = ({ product, configBase, showProductModal }) => (
  <Col
    xs={12}
    lg={6}
    style={{
      marginTop: 15,
    }}
  >
    <ProductBox config={configBase} onClick={showProductModal(product)}>
      <Col
        xs={5}
        style={{
          backgroundColor: 'white',
          backgroundImage: `url(https://d26aylt6qzro7d.cloudfront.net/${config.id}/${product.image})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          borderBottomLeftRadius: 15,
          borderTopLeftRadius: 15,
        }}
      />
      <Col xs={7} rowSpan={2}>
        <Row
          style={{
            textAlign: 'left',
            display: 'flex',
            height: '100%',
            padding: 5,
            justifyContent: 'space-beteween',
          }}
        >
          <Col xs={12} style={{ overflow: 'hidden' }}>
            <ProductName>{product.name}</ProductName>
          </Col>
          <Col xs={12} style={{ width: '100%', overflow: 'hidden' }}>
            <ProductDescriptionList>
              {product.description}
            </ProductDescriptionList>
          </Col>
          <Col xs={12}>
            {!product.price && <span>A partir de </span>}
            <ProductPrice>{getMinPrice(product)}</ProductPrice>
          </Col>
        </Row>
      </Col>
    </ProductBox>
  </Col>
);

const mapStateToProps = state => {
  const {
    config: { config: configBase },
  } = state;
  return { configBase };
};
export default connect(mapStateToProps, {})(ProductBase);
