import styled from 'styled-components';
import { Row } from 'reactstrap';

export const ProductDescriptionList = styled.p`
  font-size: 13px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60vw;
  overflow: hidden;
`;
export const ProductName = styled.h2`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;
export const ProductPrice = styled.span`
  font-weight: 500;
  margin-top: auto;
`;

export const ImageContainer = styled.div`
  padding: 15px 0 15px 15px;
  flex-shrink: 0;
  overflow: hidden;
`;

export const ProductBox = styled(Row)`
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 15px;
  margin: 0 5px;
  min-height: 130px;
  width: 100%;
  border: solid thin ${props => props.config.productCardBackgroundColor};
  :hover {
    box-shadow: 0 5px 15px ${props => props.config.productCardShadowColor};
  }
`;
