import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';

import { OrderTab, HeaderSmallLabel, HeaderBigLabel } from './style';
import { getUser, statusToText, toBlr, getTempIdOrders } from '../../util';
import Loading from '../../components/loading';
import { fetchOrders, getColors, fetchTempOrders } from '../../service';
import {
  ProductCartLabel,
  ProductCartPrice,
} from '../../components/cart/style';
import CartItem from '../../components/cart/item';
import CartFooter from '../../components/cart/footer';
import { setConfigs } from '../../redux/actions';

class Orders extends Component {
  state = {
    loading: true,
    detailOrder: false,
    order: {
      items: [],
    },
    orders: {
      completed: [],
      pending: [],
    },
  };

  async componentDidMount() {
    const user = getUser();
    if (!this.props.config) {
      const colors = await getColors();
      this.props.setConfigs({ config: { ...colors } });
    }
    const { config } = this.props;
    if (config.hasLogin === 'YES' && !user) {
      this.props.history.push(`/${process.env.REACT_APP_PROJECT}/login`);
      return;
    }
    if (config.hasLogin === 'YES') {
      const orders = await fetchOrders(user.token);
      this.setState({ orders, loading: false });
    } else {
      if (!getTempIdOrders(config.hasLogin === 'YES')) {
        this.setState({ loading: false });
        return;
      }
      const orders = await fetchTempOrders(
        getTempIdOrders(config.hasLogin === 'YES')
      );
      this.setState({ loading: false, orders });
    }
  }

  reloadOrders = async () => {
    const user = getUser();
    const {
      config: { hasLogin },
    } = this.props;
    this.setState({ loading: true });
    if (hasLogin === 'YES') {
      const orders = await fetchOrders(user.token);
      this.setState({ orders, loading: false });
    } else {
      const orders = await fetchTempOrders(getTempIdOrders(hasLogin === 'YES'));
      this.setState({ loading: false, orders });
    }
  };

  drawOrder = order => {
    return (
      <Fragment key={`order_${order.id}`}>
        <Row>
          <Col lg={2} xs={4} style={{ textAlign: 'left', fontWeight: 'bold' }}>
            #{order.id}
          </Col>
          <Col lg={2} xs={8} style={{ textAlign: 'left' }}>
            {moment(order.createdDate).local().format('DD/MM/YYYY HH:mm')}
          </Col>
          <Col lg={8} sm={4} style={{ textAlign: 'left', fontWeight: 500 }}>
            {statusToText(order.status)}
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <Row>
              <Col xs={12} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                {`O pedido vai ser ${
                  order.orderType === 0
                    ? 'retirado na loja:'
                    : 'entregue no endereço:'
                }`}
              </Col>
              <Col xs={12} style={{ textAlign: 'left' }}>
                {order.orderType === 0 && (
                  <span>
                    {order.storeName}: {order.storeAddress} {order.storeNumber}{' '}
                    {order.storeComplement}
                  </span>
                )}
                {order.orderType !== 0 && (
                  <span>
                    {order.orderAddress} {order.orderNumber}{' '}
                    {order.orderComplement}
                  </span>
                )}
              </Col>
              <Col xs={12} style={{ textAlign: 'left' }}>
                <span>
                  Previsão:{' '}
                  {moment(order.deliveryForecastDate)
                    .local()
                    .format('DD/MM/YYYY HH:mm')}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ textAlign: 'left', marginTop: 20 }}>
            <Button
              outline
              style={{ width: '50%', padding: 4 }}
              onClick={() =>
                this.setState({ detailOrder: true, order: { ...order } })
              }
            >
              Detalhar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
      </Fragment>
    );
  };

  render() {
    const { loading, orders, order, detailOrder } = this.state;
    if (!this.props.config) {
      return null;
    }
    return (
      <div
        style={{
          backgroundColor: this.props.config.headerBackgroundColor,
        }}
      >
        <Modal
          size="lg"
          isOpen={detailOrder}
          toggle={() => this.setState({ detailOrder: false })}
        >
          <ModalHeader>
            <Row>
              <Col xs={7} lg={12}>
                <Row>
                  <Col style={{ textAlign: 'left' }} sm={12} lg={4}>
                    <HeaderSmallLabel> #{order.id}</HeaderSmallLabel>
                  </Col>
                  <Col style={{ textAlign: 'left' }} sm={12} lg={8}>
                    <HeaderSmallLabel>
                      {moment(order.createdDate)
                        .local()
                        .format('DD/MM/YYYY HH:mm')}
                    </HeaderSmallLabel>
                  </Col>
                </Row>
              </Col>
              <Col sm={5} lg={12}>
                <Row>
                  <Col style={{ textAlign: 'left' }}>
                    <HeaderBigLabel>
                      {statusToText(order.status)}
                    </HeaderBigLabel>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                {`O pedido vai ser ${
                  order.orderType === 0
                    ? 'retirado na loja:'
                    : 'entregue no endereço:'
                }`}
              </Col>
              <Col xs={12} style={{ textAlign: 'left' }}>
                {order.orderType === 0 && (
                  <span>
                    {order.storeName}: {order.storeAddress} {order.storeNumber}{' '}
                    {order.storeComplement}
                  </span>
                )}
                {order.orderType !== 0 && (
                  <span>
                    {order.orderAddress} {order.orderNumber}{' '}
                    {order.orderComplement}
                  </span>
                )}
              </Col>
              <Col xs={12} style={{ textAlign: 'left' }}>
                <span>
                  Previsão:{' '}
                  {moment(order.deliveryForecastDate)
                    .local()
                    .format('DD/MM/YYYY HH:mm')}
                </span>
              </Col>
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>Produtos</Col>
            </Row>
            {order.items
              .filter(({ parentId }) => !parentId)
              .map(product => (
                <Fragment key={`product_detail_${product.id}`}>
                  <Row>
                    <Col lg={8} xs={12}>
                      <Row>
                        <Col lg={8} xs={8}>
                          <ProductCartLabel>
                            {`${product.quantity}x ${product.productName}`}
                          </ProductCartLabel>
                        </Col>
                        <Col lg={4} xs={4}>
                          <ProductCartPrice>
                            {toBlr(product.productPrice)}
                          </ProductCartPrice>
                        </Col>
                        <CartItem
                          products={order.items.filter(
                            ({ parentId }) => parentId === product.id
                          )}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Fragment>
              ))}
            <CartFooter
              discountTotal={order.discount}
              deliveryFee={order.deliveryFee}
              cartTotal={order.total}
              productTotal={order.productTotal}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              outline
              color="secondary"
              onClick={() => this.setState({ detailOrder: false })}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        <Tabs style={{ width: '100%' }}>
          <TabList>
            <OrderTab config={this.props.config}>Pedidos atuais</OrderTab>
            <OrderTab config={this.props.config}>Pedidos antigos</OrderTab>
          </TabList>
          <TabPanel>
            <Card>
              <CardBody>
                {loading && <Loading message="Carregando pedidos" />}
                {!loading && orders.pending.length === 0 && (
                  <span>Nenhum pedido</span>
                )}
                {orders.pending.map(orderMap => this.drawOrder(orderMap))}
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel>
            <Card>
              <CardBody>
                {loading && <Loading message="Carregando pedidos" />}
                {!loading && orders.completed.length === 0 && (
                  <span>Nenhum pedido</span>
                )}
                {orders.completed.map(orderMap => this.drawOrder(orderMap))}
              </CardBody>
            </Card>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};
export default connect(mapStateToProps, { setConfigs })(Orders);
