/* eslint-disable no-cond-assign */
/* eslint-disable consistent-return */
import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { FaShoppingCart } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardHeader,
  CardFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { removeHash } from 'react-scrollable-anchor';

import { toast } from 'react-toastify';
import Loading from '../../components/loading';
import {
  listCategories,
  fetchUserDetail,
  createNewAddress,
  fetchAddressInformation,
  fetchRedeemOptions,
  retrieveCampains,
  fetchStore,
  fetchDeliveryInformationV2,
  getColors,
  fetchStores,
  valideteHashcode,
} from '../../service';

import {
  addToCart,
  sumFromCart,
  subFromCart,
  setConfigs,
  loginUser,
  replaceToCart,
  removeFromCart,
  clearCart,
  applyPromoCode,
  addRedeemItem,
  setFromCookie,
  setCategories,
} from '../../redux/actions';
import {
  CloseIcon,
  CartDesktopContainer,
  CartMobileContainer,
  CategoryContainer,
  CategorySmallName,
  ProductList,
  BodyNoScroll,
} from './style';
import {
  isUserLogged,
  getCart,
  getSelectedAddress,
  getSelectedStore,
  storeSelectedAddress,
  storeSelectedStore,
  getUser,
  toBlr,
  removeSelectedAddress,
  removeSelectedStore,
  logout,
  removeStorageUser,
  findProductOnCategories,
  findCategory,
  storeUser,
} from '../../util';
import CartBody from '../../components/cart/body';
import Step from '../../components/cart/step';
import ProductView from '../../components/product';
import BannerView, { BannerSimpleView } from '../../components/banner';
import { CartModal } from '../../components/cart/style';
import CartFooter from '../../components/cart/footer';
import AddressSelectionModal from '../../components/addressSelection';
import StoreSelectionModal from '../../components/storeSelection';
import FixedAddress from '../../components/fixedAddress';
import { NewAddress } from '../../components/address';
import { Button } from '../../components/button/style';
import Points from '../../components/points';
import CartResume from '../../components/cart/resume';
import { trackEvent } from '../../util/track';

class Categories extends Component {
  state = {
    selectTab: 0,
    loading: true,
    cartMobileOpen: false,
    productDetail: false,
    cartResumeShow: true,
    selectStoreModal: false,
    addressSelectModal: false,
    product: {},
    categories: [],
    campains: [],
    height: 1000,
    wizardSteps: [],
    redeem: null,
    wizardIndex: 0,
    addressInformation: {},
    storeDetail: {},
    stores: [],
    hash: new URLSearchParams(window.location.search).get('hash'),
  };

  async componentDidMount() {
    const config = await getColors();
    const [stores, deliveryStores] = await Promise.all([
      fetchStores(config.hoursAfterShceduled),
      fetchDeliveryInformationV2({
        zip: getSelectedAddress() ? getSelectedAddress().zip : null,
        number: getSelectedAddress() ? getSelectedAddress().number : null,
        street: getSelectedAddress() ? getSelectedAddress().street : null,
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        hoursAfterShceduled: config ? config.hoursAfterShceduled : 0,
      }),
    ]);
    this.setState({ stores });
    this.props.setConfigs({ config });
    const { hash } = this.state;
    if (hash) {
      try {
        const data = await valideteHashcode(hash);
        storeSelectedAddress({
          zip: data.zip,
          number: data.number,
          complement: data.complement,
          city: data.city,
          neighborhood: data.neighborhood,
          state: data.state,
        });
        const store = deliveryStores.find(
          ({ id }) => parseInt(id, 10) === parseInt(data.storeId, 10)
        );
        if (store) {
          storeSelectedStore({
            ...store,
            id: parseInt(store.id, 10),
            storeId: parseInt(store.id, 10),
            type: 'delivery',
          });
          storeUser({ ...data });
          this.props.loginUser({ ...data });
          await this.loadCatalogByStore();
          const [campains, storeDetail] = await Promise.all([
            retrieveCampains(
              getSelectedStore().id || getSelectedStore().storeId
            ),
            fetchStore(
              getSelectedStore().id || getSelectedStore().storeId,
              this.props.config ? this.props.config.hoursAfterShceduled : 0
            ),
          ]);
          this.setState({
            campains,
            storeDetail: {
              ...storeDetail,
              open:
                (this.props.config &&
                  this.props.config.hasScheduledOrder === 'YES') ||
                storeDetail.open,
            },
          });
          if (!getUser()) return true;

          const redeem = await fetchRedeemOptions({
            token: getUser().token,
            storeId: getSelectedStore().id || getSelectedStore().storeId,
          });
          this.setState({ redeem });
          return;
        }
        alert('A loja selecionada não entrega no endereço solicitado');
      } catch (e) {
        console.log('e', e);
      }
    }
    try {
      if (
        getSelectedStore()?.type === 'delivery' &&
        config.hasDelivery === 'NO'
      ) {
        removeSelectedAddress();
        removeSelectedStore();
        toast.info(
          `
          Os pedidos por entrega estão desabilitados.
          Por favor, selecione a loja novamente.
        `,
          { autoClose: 10000 }
        );
      }

      if (
        getSelectedStore()?.type === 'takeaway' &&
        config.hasTakeAway === 'NO'
      ) {
        removeSelectedStore();
        toast.info(
          `
          Os pedidos com retirada no estabelecimento estão desabilitados.
          Por favor, selecione a loja novamente.
        `,
          { autoClose: 10000 }
        );
      }

      if (config.hasLogin === 'NO') logout();
      if (config.hasLogin === 'YES') removeStorageUser();
    } catch (err) {
      console.log('error: ', err);
    }
    if (
      stores.length === 1 &&
      !getSelectedAddress() &&
      (!getSelectedStore() || getSelectedStore().type === 'NONE')
    ) {
      this.confirmStoreSelection({
        ...stores[0],
        type: config.hasDelivery === 'NO' ? 'takeaway' : 'NONE',
      });
      return;
    }
    this.showStoreAddressModal();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  showStoreAddressModal = async () => {
    const { config } = this.props;
    try {
      if (
        (!getSelectedStore() || getSelectedStore().type === 'NONE') &&
        config.hasDelivery === 'YES'
      ) {
        this.setState({ addressSelectModal: true, loading: false });
        return false;
      }
      if (!getSelectedStore() && config.hasDelivery === 'NO') {
        const stores = await fetchStores(config.hoursAfterShceduled);
        if (stores.length === 1) {
          this.confirmStoreSelection({ ...stores[0], type: 'takeaway' });
        }
        this.setState({ selectStoreModal: true, loading: false });
        return;
      }
    } catch (e) {
      alert(`erro ao pegar endereço: ${e}`);
    }

    try {
      if (
        getSelectedAddress() &&
        getSelectedStore() &&
        getSelectedStore().type === 'delivery'
      ) {
        const stores = await fetchDeliveryInformationV2({
          zip: getSelectedAddress() ? getSelectedAddress().zip : null,
          number: getSelectedAddress() ? getSelectedAddress().number : null,
          street: getSelectedAddress() ? getSelectedAddress().street : null,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          hoursAfterShceduled: this.props.config
            ? this.props.config.hoursAfterShceduled
            : 0,
        });

        const updatedStore = stores.find(
          ({ storeId }) =>
            storeId === getSelectedStore().id ||
            storeId === getSelectedStore().storeId
        );
        if (!updatedStore) {
          storeSelectedStore(null);
          this.setState({ selectStoreModal: true, loading: false });
          return false;
        }
        delete updatedStore.type;
        storeSelectedStore({
          ...getSelectedStore(),
          ...updatedStore,
        });
      }

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);

      await this.loadCatalogByStore();
      const [campains, storeDetail] = await Promise.all([
        retrieveCampains(getSelectedStore().id || getSelectedStore().storeId),
        fetchStore(
          getSelectedStore().id || getSelectedStore().storeId,
          this.props.config ? this.props.config.hoursAfterShceduled : 0
        ),
      ]);
      this.setState({
        campains,
        storeDetail: {
          ...storeDetail,
          open:
            (this.props.config &&
              this.props.config.hasScheduledOrder === 'YES') ||
            storeDetail.open,
        },
      });
      if (!getUser()) return true;

      const redeem = await fetchRedeemOptions({
        token: getUser().token,
        storeId: getSelectedStore().id || getSelectedStore().storeId,
      });
      this.setState({ redeem });
      return true;
    } catch (error) {
      console.log('error: ', error);
    }
  };

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  loadCatalogByStore = async () => {
    const {
      match: { params },
    } = this.props;

    const data = await listCategories(
      getSelectedStore().id || getSelectedStore().storeId
    );
    const categories = data
      .filter(item => {
        return item.items.length > 0 || item.categories.length > 0;
      })
      .filter(item => {
        return item.items.length > 0 || item.categories.length > 0;
      });
    this.props.setCategories(categories);
    const cookieCart = getCart();
    if (cookieCart) {
      this.props.setFromCookie(cookieCart);
    }
    this.setState({ categories, loading: false });
    if (params && params.id) {
      this.editProductModal(
        cookieCart.products.find(
          ({ id: productId }) =>
            parseInt(productId, 10) === parseInt(params.id, 10)
        )
      );
    }
    if (params && params.sku) {
      console.log('params.sku: ', params.sku);
      categories.forEach(category => {
        if (
          category.name.split(' ').join('-').split('/').join('+') === params.sku
        ) {
          if (category) {
            setTimeout(() => this.scrollTo(category), 500);
          }
        }
        if (category.items) {
          const foundedProduct = category.items.find(item => {
            return (
              `${item.sku}` === `${params.sku}` ||
              item.name.split(' ').join('-').split('/').join('+') === params.sku
            );
          });
          if (foundedProduct) {
            this.showProductModal(foundedProduct)();
            return;
          }
        }
        if (category.categories) {
          category.categories.forEach(categoryMap => {
            if (
              categoryMap.name.split(' ').join('-').split('/').join('+') ===
              params.sku
            ) {
              if (categoryMap) {
                setTimeout(() => this.scrollTo(categoryMap), 500);
              }
            }
            const foundedProduct = categoryMap.items.find(item => {
              console.log(
                `comparing |${item.name
                  .split(' ')
                  .join('-')
                  .split('/')
                  .join('+')}| with |${params.sku}| = ${
                  item.name.split(' ').join('-').split('/').join('+') ===
                  params.sku
                }`
              );
              return (
                `${item.sku}` === `${params.sku}` ||
                item.name.split(' ').join('-').split('/').join('+') ===
                  params.sku
              );
            });
            if (foundedProduct) {
              this.showProductModal(foundedProduct)();
            }
          });
        }
      });
    }

    if (window.location.hash) {
      this.setState({
        selectedCategoryId: parseInt(window.location.hash.replace('#', ''), 10),
      });
      removeHash();
    } else {
      this.setState({
        selectedCategoryId: null,
      });
    }
  };

  subtractProductFromCart = product => {
    if (product.quantity === 1) {
      this.setState({ cartMobileOpen: false });
      confirmAlert({
        title: 'Remover produto',
        message: 'Deseja remover este produto do carrinho?',
        buttons: [
          {
            label: 'Sim',
            onClick: () => {
              trackEvent({
                name: 'removeProductFromCart',
                data: {
                  productId: product.id,
                  productName: product.name,
                  user: getUser(),
                },
              });

              this.props.removeFromCart(product.cartId);
            },
          },
          {
            label: 'Não',
            onClick: () => this.setState({ cartMobileOpen: isMobile }),
          },
        ],
      });
    } else {
      trackEvent({
        name: 'subtractProductQuantity',
        data: {
          productId: product.id,
          productName: product.name,
          user: getUser(),
        },
      });
      this.props.subFromCart(product.cartId);
    }
  };

  sumProductFromCart = product => {
    this.props.sumFromCart(product.cartId);
    trackEvent({
      name: 'sumProductQuantity',
      data: {
        productId: product.id,
        productName: product.name,
        user: getUser(),
      },
    });
  };

  subtractProduct = () => {
    const { product } = this.state;
    if (product.quantity > 1) {
      trackEvent({
        name: 'subtractProductQuantity',
        data: {
          productId: product.id,
          productName: product.name,
          user: getUser(),
        },
      });

      this.setState({
        product: { ...product, quantity: product.quantity - 1 },
      });
    }
  };

  sumProduct = () => {
    const { product } = this.state;
    trackEvent({
      name: 'sumProductQuantity',
      data: {
        productId: product.id,
        productName: product.name,
        user: getUser(),
      },
    });

    this.setState({
      product: { ...product, quantity: product.quantity + 1 },
    });
  };

  addProduct = () => {
    const {
      match: { params },
    } = this.props;

    const { product, wizardSteps } = this.state;
    // eslint-disable-next-line no-shadow
    const { addToCart, replaceToCart, cart } = this.props;
    if (!params || !params.id) {
      addToCart({ ...product, wizardSteps: [...wizardSteps] });
    } else {
      replaceToCart({ ...product, wizardSteps: [...wizardSteps] });
    }
    window.history.replaceState(
      null,
      null,
      `/${process.env.REACT_APP_PROJECT}`
    );
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.pageview(`/${process.env.REACT_APP_PROJECT}`);
    }
    if (process.env.REACT_APP_PIXEL) {
      ReactPixel.pageView();
    }
    this.setState({
      selectedCategoryId: null,
      product: {},
      cartResumeShow: true,
      wizardSteps: [],
      simple: false,
      wizardIndex: 0,
      productDetail: false,
    });
    const baseElement = window.document.getElementById('categoryContainer');
    trackEvent({
      name: 'AddToCart',
      data: {
        productId: product.id,
        productName: product.name,
        cartJson: JSON.stringify(cart),
        user: getUser(),
      },
    });
    if (baseElement) {
      baseElement.scrollTop = 0;
    }
  };

  editProductModal = product => {
    const { items: subItems, wizardSteps } = product;
    console.log('wizardSteps: ', wizardSteps);
    this.setState({ wizardSteps: [], wizardIndex: 0, product: {} }, () => {
      this.setState({
        productDetail: true,
        wizardSteps,
        product,
        simple: subItems.length === 0,
      });
    });
  };

  showProductUrl = product => () => {
    trackEvent({
      name: 'openProductDetail',
      data: {
        productId: product.id,
        productName: product.name,
        user: getUser(),
      },
    });

    window.history.replaceState(
      null,
      null,
      `/${process.env.REACT_APP_PROJECT}/open/${product.name
        .split(' ')
        .join('-')
        .split('/')
        .join('+')}`
    );
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.pageview(
        `/${process.env.REACT_APP_PROJECT}/open/${product.name
          .split(' ')
          .join('-')
          .split('/')
          .join('+')}`
      );
    }
    if (process.env.REACT_APP_PIXEL) {
      ReactPixel.pageView();
    }
    this.showProductModal(product)();
  };

  showProductModal = product => () => {
    trackEvent({
      name: 'openProductDetail',
      data: {
        productId: product.id,
        productName: product.name,
        user: getUser(),
      },
    });
    const { items: subItems, wizardSteps: wizardStepsProduct = null } = product;

    this.setState({ wizardSteps: [], wizardIndex: 0, product: {} }, () => {
      this.setState({
        productDetail: true,
        wizardSteps:
          wizardStepsProduct || subItems.length !== 0
            ? subItems
                .filter(subItem => subItem.items.length !== 0)
                .map(item => {
                  return {
                    formData: {},
                    id: item.id,
                    required: item.min !== 0,
                    min: item.min,
                    max: item.max,
                    type: item.type,
                    optionType: item.max === 1 ? 'SINGLE' : 'MULTIPLE',
                    title: item.name,
                    description: item.description,
                    options: [
                      ...item.items.map(option => ({
                        ...option,
                        quantity: option.quantity || 0,
                      })),
                    ],
                  };
                })
            : [],
        product: { ...product, quantity: 1 },
        simple: subItems.length === 0,
      });
    });
  };

  closeProductDetail = () => {
    window.history.replaceState(
      null,
      null,
      `/${process.env.REACT_APP_PROJECT}`
    );
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.pageview(`/${process.env.REACT_APP_PROJECT}`);
    }
    if (process.env.REACT_APP_PIXEL) {
      ReactPixel.pageView();
    }
    this.setState({
      product: {},
      cartResumeShow: true,
      wizardSteps: [],
      simple: false,
      wizardIndex: 0,
      productDetail: false,
    });
  };

  sumOptional =
    ({ step, option }) =>
    () => {
      const { wizardSteps, product } = this.state;
      const quantity = (option.quantity || 0) + 1;
      trackEvent({
        name: 'sumProductOptional',
        data: {
          productId: product.id,
          productName: product.name,
          option,
          user: getUser(),
        },
      });
      const countSelectedOptionals = step.options.reduce(
        (previusValue, currentValue, index, items) => {
          return previusValue + items[index].quantity;
        },
        1
      );
      if (countSelectedOptionals > step.max) return;

      const wizardStepsToState = wizardSteps.map(stepWizard => {
        if (step.id !== stepWizard.id) return stepWizard;
        return {
          ...stepWizard,
          options: stepWizard.options.map(optionStep => {
            if (optionStep.id !== option.id) return optionStep;
            return {
              ...optionStep,
              quantity,
            };
          }),
        };
      });

      this.setState({
        product: {
          ...product,
          multiple: wizardStepsToState,
        },
        wizardSteps: wizardStepsToState,
      });
    };

  subtractOptional =
    ({ step, option }) =>
    () => {
      const { wizardSteps, product } = this.state;
      const quantity = (option.quantity || 0) - 1;

      if (quantity < 0) return;
      trackEvent({
        name: 'subtractProductOptional',
        data: {
          productId: product.id,
          productName: product.name,
          option,
          user: getUser(),
        },
      });
      const wizardStepsToState = wizardSteps.map(stepWizard => {
        if (step.id !== stepWizard.id) return stepWizard;
        return {
          ...stepWizard,
          options: stepWizard.options.map(optionStep => {
            if (optionStep.id !== option.id) return optionStep;
            return {
              ...optionStep,
              quantity,
            };
          }),
        };
      });
      this.setState({
        product: {
          ...product,
          multiple: wizardStepsToState,
        },
        wizardSteps: wizardStepsToState,
      });
    };

  findProductInsideCatalog = ({ productId }) => {
    const find = product => product.id === productId;

    const { categories } = this.state;
    return new Promise(resolve => {
      categories.forEach(category => {
        const item = category.items.find(find);
        if (item) {
          resolve(item);
          return;
        }
        if (category.categories) {
          category.categories.forEach(subCategory => {
            const subItem = subCategory.items.find(find);
            if (subItem) {
              resolve(subItem);
            }
          });
        }
      });
    });
  };

  addRedeemItem = async item => {
    if (item.discountValue > this.props.cart.total) {
      alert('O valor do disconto, não pode ser maior que o valor da compra.');
      return;
    }
    if (item.productId) {
      const product = await this.findProductInsideCatalog({
        productId: item.productId,
      });
      this.showProductModal({
        ...product,
        price: 0,
        priceDelivery: 0,
        redeemId: item.id,
      })();
    } else {
      this.props.addRedeemItem(item);
    }
  };

  goPayment = async () => {
    trackEvent({
      name: 'willNavigateToCart',
      data: {
        isLoggedUser: isUserLogged(),
        user: getUser(),
      },
    });
    if (!getSelectedStore() || getSelectedStore().type === 'NONE') {
      this.openStoreSelection();
      return;
    }
    const { config } = this.props;
    const paymentFunctions = {
      false: () =>
        this.props.history.push(`/${process.env.REACT_APP_PROJECT}/login`),
      true: () =>
        this.props.history.push(`/${process.env.REACT_APP_PROJECT}/checkout`),
    };
    paymentFunctions[config.hasLogin === 'NO' || isUserLogged()]();
  };

  modifyProduct = () =>
    this.setState(prevState => ({
      ...prevState,
      wizardIndex: prevState.wizardIndex - 1,
    }));

  findPos = obj => {
    let curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop;
        // eslint-disable-next-line no-param-reassign
      } while ((obj = obj.offsetParent));
      return [curtop];
    }
    return false;
  };

  scrollTo = category => {
    trackEvent({
      name: 'scrollingTo',
      data: { productId: category.id, user: getUser() },
    });

    if (!category) return;

    window.history.replaceState(
      null,
      null,

      `/${process.env.REACT_APP_PROJECT}/open/${category.name
        .split(' ')
        .join('-')
        .split('/')
        .join('+')}`
    );
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.pageview(
        `/${process.env.REACT_APP_PROJECT}/open/${category.name
          .split(' ')
          .join('-')
          .split('/')
          .join('+')}`
      );
    }
    if (process.env.REACT_APP_PIXEL) {
      ReactPixel.pageView();
    }
    const baseElement = document.querySelector('html');
    if (!baseElement || !window.document.getElementById(category.id)) return;
    // const top = window.document.getElementById(category.id).offsetTop + 110;
    // baseElement.scrollTo(0, top);

    window.scroll(0, this.findPos(document.getElementById(category.id)) - 145);
    // Finds y value of given object
    this.setState({ dropdownOpen: false });
  };

  openStoreSelection = async () => {
    this.setState({
      addressSelectModal: false,
      selectStoreModal: true,
      selectTab: 1,
    });
  };

  confirmAddressSelection = async addressInformation => {
    storeSelectedAddress(addressInformation);
    this.setState({ addressSelectModal: false, selectStoreModal: true });
  };

  confirmStoreSelection = async store => {
    // console.log('location: ', window.location);
    window.history.replaceState(
      null,
      null,
      `/${process.env.REACT_APP_PROJECT}`
    );
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.pageview(`/${process.env.REACT_APP_PROJECT}`);
    }
    if (process.env.REACT_APP_PIXEL) {
      ReactPixel.pageView();
    }
    if (
      store.id !== (getSelectedStore() || {}).id &&
      store.id !== (getSelectedStore() || {}).storeId
    ) {
      this.props.clearCart();
    }
    storeSelectedStore(store);
    this.setState({
      addressSelectModal: false,
      selectStoreModal: false,
      loading: true,
    });
    await this.loadCatalogByStore();
    const [campains, storeDetail] = await Promise.all([
      retrieveCampains(getSelectedStore().id || getSelectedStore().storeId),
      fetchStore(
        getSelectedStore().id || getSelectedStore().storeId,
        this.props.config.hoursAfterShceduled
      ),
    ]);
    this.setState({ campains, storeDetail });
  };

  createAddress = async e => {
    e.preventDefault();
    this.setState({ loadingCep: true });
    const user = getUser();
    const address = {
      ...this.state.addressInformation,
      cep: this.state.addressInformation.zip,
    };
    delete address.zip;
    delete address.canEditStreet;
    await createNewAddress({
      body: {
        address,
      },
      token: user.token,
    });
    this.setState({
      loadingCep: false,
      newAddressPopup: false,
      loading: true,
    });
    const customer = await fetchUserDetail({ token: user.token });

    this.setState({ customer, loading: false, addressSelectModal: true });
  };

  loadAddressFromCep = async zipCode => {
    this.setState({ loadAddressFromCep: true });
    const addressInformation = await fetchAddressInformation(zipCode)
      .then(addressInformationDB => {
        return {
          ...addressInformationDB,
          canEditStreet:
            !addressInformationDB.street ||
            addressInformationDB.street.trim() === '',
          number: '',
          complement: '',
        };
      })
      .catch(() => ({
        error: 'Cep não encontrado',
      }));

    this.setState({ loadAddressFromCep: false, addressInformation });
  };

  toggle = () =>
    this.setState(prevState => ({
      ...prevState,
      dropdownOpen: !prevState.dropdownOpen,
    }));

  onTakeAway = async () => {
    if (this.state.stores.length === 1 && getSelectedAddress()) {
      const deliveryStores = await fetchDeliveryInformationV2({
        zip: getSelectedAddress() ? getSelectedAddress().zip : null,
        number: getSelectedAddress() ? getSelectedAddress().number : null,
        street: getSelectedAddress() ? getSelectedAddress().street : null,
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        hoursAfterShceduled: this.props.config.hoursAfterShceduled,
      });
      if (deliveryStores.length === 0) {
        this.setState({ selectStoreModal: true, selectTab: 0 });
        return;
      }
      this.confirmStoreSelection({
        ...this.state.stores[0],
        type: 'delivery',
      });
    } else {
      this.setState({ selectStoreModal: true, selectTab: 0 });
    }
  };

  onDelivery = () => {
    if (this.state.stores.length === 1) {
      this.confirmStoreSelection({
        ...this.state.stores[0],
        type: 'takeaway',
      });
    } else {
      this.setState({ selectStoreModal: true, selectTab: 1 });
    }
  };

  render() {
    const { loading, categories, product, cartResumeShow, campains } =
      this.state;
    const bannersElement = [];
    const simpleBannersElement = [];
    const categoryElements = [];

    campains.forEach(campaing =>
      simpleBannersElement.push(
        <BannerSimpleView
          key={`campaing_${campaing.id}`}
          bannerIndex={campaing.id}
          onClick={() => {
            if (campaing.categoryId) {
              const { category, parentCategoryId } = findCategory(
                campaing.categoryId,
                this.state.categories
              );

              if (!category) return;

              if (parentCategoryId) {
                this.setState({ selectedCategoryId: parentCategoryId }, () => {
                  this.scrollTo(category);
                });
              } else {
                this.setState({ selectedCategoryId: category.id }, () => {
                  this.scrollTo(category);
                });
              }
            }
            if (campaing.productId) {
              const productOnCategory = findProductOnCategories(
                campaing.productId,
                categories
              );
              if (productOnCategory) this.showProductModal(productOnCategory)();
            }
          }}
          campaing={campaing}
        />
      )
    );
    categories
      .filter(
        item => item.categories && item.categories.length > 0 && item.image
      )
      .forEach(parent => {
        bannersElement.push(
          <BannerView
            configColor={this.props.config}
            key={`categoryIndex_parent_${parent.id}`}
            category={parent}
            onClick={() => {
              const { category, parentCategoryId } = findCategory(
                parent.id,
                this.state.categories
              );

              if (!category) return;

              if (parentCategoryId) {
                this.setState({ selectedCategoryId: parentCategoryId }, () => {
                  this.scrollTo(category);
                });
              } else {
                this.setState({ selectedCategoryId: category.id }, () => {
                  this.scrollTo(category);
                });
              }
            }}
          />
        );
      });
    const { cart } = this.props;
    const { simple, height, redeem } = this.state;
    const { wizardSteps, wizardIndex } = this.state;
    const step = wizardSteps[wizardIndex];
    if (!this.props.config) {
      return <Loading message="Carregando..." messageColor="black" />;
    }
    const hideCategories =
      this.props.config.hideNotSelectedCategories === 'YES';
    return (
      <div>
        <NewAddress
          createAddress={this.createAddress}
          addressInformation={this.state.addressInformation}
          loadingCep={this.state.loadingCep}
          onChange={field => value => {
            this.setState({ [field]: value });
            if (
              field === 'cep' &&
              value.replace('_', '').replace('-', '').length === 8
            ) {
              const cep = value.replace('_', '').replace('-', '');
              this.loadAddressFromCep(cep);
            }
            if (field !== 'cep') {
              this.setState(prevState => ({
                ...prevState,
                addressInformation: {
                  ...prevState.addressInformation,
                  [field]: value,
                },
              }));
            }
          }}
          toggle={() => this.setState({ newAddressPopup: false })}
          isOpen={this.state.newAddressPopup}
        />

        <FixedAddress
          hideAddress={false}
          openAddress={() => this.setState({ addressSelectModal: true })}
          onTakeAway={this.onTakeAway}
          onDelivery={this.onDelivery}
        />

        {this.state.addressSelectModal && (
          <AddressSelectionModal
            openNewAddress={() =>
              this.setState({
                newAddressPopup: true,
                addressSelectModal: false,
              })
            }
            cancelAction={() =>
              this.setState({
                addressSelectModal: false,
                selectStoreModal: true,
              })
            }
            isOpen={this.state.addressSelectModal}
            openStoreSelection={this.openStoreSelection}
            confirmAction={this.confirmAddressSelection}
            stores={this.state.stores}
          />
        )}
        {this.state.selectStoreModal && (
          <StoreSelectionModal
            changeAddress={() =>
              this.setState({
                selectStoreModal: false,
                addressSelectModal: true,
              })
            }
            cancelAction={() => this.setState({ selectStoreModal: false })}
            selectTab={this.state.selectTab}
            isOpen={this.state.selectStoreModal}
            confirmAction={this.confirmStoreSelection}
          />
        )}
        <div className="my-modal-wrapper">
          <Modal
            isOpen={this.state.productDetail}
            size="lg"
            toggle={this.closeProductDetail}
          >
            <ModalHeader>
              <CloseIcon onClick={this.closeProductDetail}>Cancelar</CloseIcon>
            </ModalHeader>

            {wizardSteps.length > 0 && cartResumeShow && (
              <CartResume
                product={product}
                startStep={() => {
                  this.setState({ cartResumeShow: false });
                }}
              />
            )}
            {(wizardSteps.length === 0 || !cartResumeShow) && (
              <Step
                height={height}
                simpleItem={simple}
                step={step}
                subtractProduct={this.subtractProduct}
                sumProduct={this.sumProduct}
                product={product}
                updateWizardSteps={wizarSteps => this.setState({ wizarSteps })}
                updateObservation={value => this.setState({ product: value })}
                wizardSteps={wizardSteps}
                subtractOptional={this.subtractOptional}
                sumOptional={this.sumOptional}
                wizardIndex={wizardIndex}
                moveBack={() => {
                  trackEvent({
                    name: 'clickOnStepBack',
                    data: {
                      step: step.title,
                      user: getUser(),
                    },
                  });
                  this.setState(prevState => ({
                    ...prevState,
                    wizardIndex: prevState.wizardIndex - 1,
                  }));
                }}
                moveNext={() => {
                  trackEvent({
                    name: 'clickOnStepNext',
                    data: {
                      step: step.title,
                      user: getUser(),
                    },
                  });
                  this.setState(prevState => ({
                    ...prevState,
                    wizardIndex: prevState.wizardIndex + 1,
                  }));
                }}
              />
            )}
            {!simple && cartResumeShow && (
              <ModalFooter>
                <Button
                  outline
                  color="danger"
                  onClick={this.closeProductDetail}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={!this.state.storeDetail.open}
                  onClick={() => this.setState({ cartResumeShow: false })}
                >
                  {this.state.storeDetail.open ? 'Avançar' : 'Loja fechada'}
                </Button>
              </ModalFooter>
            )}
            {(simple || !step) && (
              <ModalFooter>
                {wizardIndex !== 0 && (
                  <Button
                    outline
                    onClick={this.modifyProduct}
                    disabled={!this.state.storeDetail.open}
                  >
                    Modificar
                  </Button>
                )}

                <Button
                  onClick={this.addProduct}
                  disabled={!this.state.storeDetail.open}
                >
                  {this.state.storeDetail.open
                    ? 'Adicionar ao Carrinho'
                    : 'Loja Fechada'}
                </Button>
              </ModalFooter>
            )}
          </Modal>
          <CartModal isOpen={this.state.cartMobileOpen} size="lg">
            <ModalHeader>
              Carrinho
              <CloseIcon
                onClick={() => this.setState({ cartMobileOpen: false })}
              >
                Fechar
              </CloseIcon>
            </ModalHeader>

            <ModalBody>
              <CartBody
                cart={cart}
                subtractProductFromCart={this.subtractProductFromCart}
                sumProductFromCart={this.sumProductFromCart}
              />
            </ModalBody>
            {this.state.storeDetail.open ? (
              <CartFooter
                cart={cart}
                deliveryFee={
                  getSelectedStore() ? getSelectedStore().deliveryFee : 0
                }
              />
            ) : (
              <div />
            )}
            {cart.products.length > 0 && (
              <ModalFooter>
                <Button
                  onClick={this.goPayment}
                  disabled={this.state.storeDetail.orderMinValue > cart.total}
                >
                  Finalizar Pedido
                </Button>
                {this.state.storeDetail.orderMinValue > cart.total && (
                  <div style={{ color: 'red' }}>
                    Valor mínimo: {toBlr(this.state.storeDetail.orderMinValue)}
                  </div>
                )}
              </ModalFooter>
            )}
          </CartModal>
        </div>
        {!loading && categoryElements}
        <CartMobileContainer
          onClick={() =>
            !this.state.storeDetail.open
              ? null
              : this.setState(prevState => ({
                  ...prevState,
                  cartMobileOpen: !prevState.cartMobileOpen,
                }))
          }
        >
          {cart.products.length > 0 && (
            <CardHeader
              style={{
                backgroundColor: this.props.config.headerBackgroundColor,
                color: this.props.config.textBaseColor,
              }}
            >
              <FaShoppingCart /> Carrinho ({cart.products.length})
            </CardHeader>
          )}
          {this.state.storeDetail.open !== undefined &&
            !this.state.storeDetail.open && (
              <Row>
                <Col xs={12}>
                  <h2 style={{ fontSize: 20, margin: 10 }}>
                    Loja fechada no momento
                  </h2>
                </Col>
              </Row>
            )}

          {cart.products.length === 0 && (
            <>
              <CardHeader
                style={{
                  backgroundColor: this.props.config.headerBackgroundColor,
                  color: this.props.config.textBaseColor,
                }}
              >
                <FaShoppingCart /> Seu carrinho está vazio
              </CardHeader>
            </>
          )}
          <div
            style={{
              display: 'none',
              fontSize: 12,
              zIndex: 200,
              backgroundColor: this.props.config.headerBackgroundColor,
              color: this.props.config.textBaseColor,
            }}
          >
            v2.5.0.3 (https://opdv.com.br)
          </div>
        </CartMobileContainer>
        {loading && (
          <Row>
            <Col xs={12} style={{ height: '100%', marginTop: 30 }}>
              <Loading
                message="Carregando cardápio"
                messageColor={this.props.config.textBaseColor}
              />
            </Col>
          </Row>
        )}
        <div>
          {redeem && !redeem.message && isMobile && (
            <div
              style={{
                marginTop: 40,
                marginHorizontal: 4,
                borderColor: this.props.config.headerBackgroundColor,
                borderWidth: 1 / 2,
                backgroundColor: this.props.config.headerBackgroundColor,
              }}
            >
              <Points
                cart={cart}
                redeem={redeem}
                addRedeemItem={this.addRedeemItem}
              />
            </div>
          )}
          <Row
            style={{
              zIndex: 2,
            }}
          >
            {!loading && !hideCategories && (
              <Col
                xs={12}
                style={{
                  marginTop: redeem && !redeem.message && isMobile ? 0 : 40,
                }}
              >
                <Dropdown isOpen={this.dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle
                    caret
                    style={{
                      color: this.props.config.textBaseColor,
                      backgroundColor: this.props.config.headerBackgroundColor,
                      position: 'fixed',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      zIndex: 10,
                    }}
                  >
                    Filtrar Categoria
                  </DropdownToggle>
                  <DropdownMenu>
                    {categories
                      .filter(
                        category =>
                          (!hideCategories ||
                            category.id === this.state.selectedCategoryId) &&
                          ((category.categories &&
                            category.categories.filter(
                              ({ items }) => items && items.length > 0
                            ).length > 0) ||
                            category.items.length > 0)
                      )
                      .map(category => (
                        <DropdownItem
                          className="dropdown-item dropdown-item"
                          onClick={() => {
                            this.scrollTo(category);
                          }}
                          key={`_filter_${category.id}`}
                        >
                          {category.name}
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                </Dropdown>
              </Col>
            )}
          </Row>
          <Modal
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
            size="sm"
            className="noBackground"
          >
            <ModalBody
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                backgroundColor: 'transparent',
              }}
            >
              <DropdownMenu
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  top: 0,
                  position: 'relative',
                }}
              >
                {categories
                  .filter(
                    category =>
                      (!hideCategories ||
                        category.id === this.state.selectedCategoryId) &&
                      ((category.categories &&
                        category.categories.filter(
                          ({ items }) => items && items.length > 0
                        ).length > 0) ||
                        category.items.length > 0)
                  )
                  .map(category => (
                    <DropdownItem
                      className="dropdown-item dropdown-item"
                      onClick={() => {
                        this.scrollTo(category);
                      }}
                      key={`_filter_${category.id}`}
                    >
                      {category.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </ModalBody>
          </Modal>
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            <Col
              id="categoryContainer"
              lg={8}
              xs={12}
              style={{
                padding: 5,
              }}
            >
              {!loading && (
                <Row>
                  <Col lg={12} xs={12}>
                    {!loading && (
                      <div style={{ marginTop: 50 }}>
                        <Row style={{ marginBottom: 20 }}>
                          {simpleBannersElement}
                        </Row>
                        <Row>{bannersElement}</Row>
                      </div>
                    )}
                  </Col>
                  <ProductList xs={12}>
                    {categories
                      .filter(
                        category =>
                          (!hideCategories ||
                            category.id === this.state.selectedCategoryId) &&
                          ((category.categories &&
                            category.categories.filter(
                              ({ items }) => items && items.length > 0
                            ).length > 0) ||
                            category.items.length > 0)
                      )
                      .map(category => {
                        return (
                          <Row
                            key={`${category.id}__parent`}
                            id={`${category.id}`}
                          >
                            <CategoryContainer>
                              {category.categories &&
                                category.categories.length > 0 && (
                                  <section id={`${category.id}`}>
                                    <h1
                                      style={{
                                        margin: 45,
                                        color:
                                          this.props.config.textCategoryColor,
                                        textAlign: 'left',
                                      }}
                                    >
                                      {category.name}
                                    </h1>
                                  </section>
                                )}
                              {(!category.categories ||
                                category.categories.length === 0) && (
                                <section id={`${category.id}`}>
                                  <CategorySmallName config={this.props.config}>
                                    {category.name}
                                  </CategorySmallName>
                                </section>
                              )}

                              {category.categories &&
                                category.categories
                                  .filter(
                                    ({ items }) => items && items.length > 0
                                  )
                                  .map(subCategory => {
                                    return (
                                      <Fragment key={subCategory.id}>
                                        <Row
                                          style={{
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'baseline',
                                          }}
                                        >
                                          <Col xs={12}>
                                            <div style={{ paddingLeft: 11 }}>
                                              <CategorySmallName
                                                id={subCategory.id}
                                                config={this.props.config}
                                              >
                                                {subCategory.name || '????'}
                                              </CategorySmallName>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row
                                          style={{
                                            marginLeft: 0,
                                            marginRight: 0,
                                          }}
                                        >
                                          {subCategory.items &&
                                            subCategory.items.map(
                                              productMap => (
                                                <ProductView
                                                  key={`extra_unique_key_${productMap.id}`}
                                                  product={productMap}
                                                  showProductModal={
                                                    this.showProductUrl
                                                  }
                                                />
                                              )
                                            )}
                                        </Row>
                                      </Fragment>
                                    );
                                  })}
                              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                {category.items &&
                                  category.items.map(productMap => (
                                    <ProductView
                                      key={`extra_unique_key_${productMap.id}`}
                                      product={productMap}
                                      showProductModal={this.showProductUrl}
                                    />
                                  ))}
                              </Row>
                            </CategoryContainer>
                          </Row>
                        );
                      })}
                  </ProductList>
                </Row>
              )}
            </Col>
            {!loading && (
              <Col
                lg={4}
                sm={12}
                style={{
                  padding: 12,
                  position: 'fixed',
                  right: 0,
                  top: 150,
                }}
              >
                <CartDesktopContainer style={{ padding: 0 }}>
                  {redeem !== null && (
                    <CardHeader
                      style={{
                        padding: 0,
                        backgroundColor:
                          this.props.config.bottomBackgroundColor,
                      }}
                    >
                      <Points
                        cart={cart}
                        redeem={redeem}
                        addRedeemItem={this.addRedeemItem}
                      />
                    </CardHeader>
                  )}
                  <BodyNoScroll>
                    {this.state.storeDetail.open !== undefined &&
                      !this.state.storeDetail.open && (
                        <Row>
                          <Col xs={12} style={{ padding: '1rem' }}>
                            <h2
                              style={{
                                marginBottom: 0,
                                padding: '0.5rem',
                                fontSize: 28,
                              }}
                            >
                              Loja fechada no momento
                            </h2>
                          </Col>
                        </Row>
                      )}
                    {this.state.storeDetail.open !== undefined &&
                    this.state.storeDetail.open ? (
                      <>
                        <div
                          style={{
                            height:
                              this.state.height > 750
                                ? this.state.height * 0.35
                                : this.state.height * 0.28,
                            overflowY: 'overlay',
                            overflowX: 'hidden',
                            padding: 30,
                          }}
                        >
                          {cart.products.length === 0 && (
                            <Row
                              style={{
                                overflow: 'hidden!important',
                                fontWeight: 'bold',
                              }}
                            >
                              <Col xs={12}>Seu carrinho está vazio</Col>
                            </Row>
                          )}
                          <CartBody
                            cart={cart}
                            subtractProductFromCart={
                              this.subtractProductFromCart
                            }
                            sumProductFromCart={this.sumProductFromCart}
                          />
                        </div>
                      </>
                    ) : (
                      <div />
                    )}
                  </BodyNoScroll>
                  {this.state.storeDetail.open && cart.products.length > 0 ? (
                    <CartFooter
                      cart={cart}
                      deliveryFee={
                        getSelectedStore() ? getSelectedStore().deliveryFee : 0
                      }
                    />
                  ) : (
                    <div />
                  )}
                  {cart.products.length > 0 && this.state.storeDetail.open ? (
                    <CardFooter>
                      <Button
                        onClick={this.goPayment}
                        disabled={
                          !this.state.storeDetail.open ||
                          this.state.storeDetail.orderMinValue > cart.total
                        }
                      >
                        Finalizar Pedido
                      </Button>
                      {this.state.storeDetail.orderMinValue > cart.total && (
                        <div style={{ color: 'red' }}>
                          Valor mínimo:
                          {toBlr(this.state.storeDetail.orderMinValue)}
                        </div>
                      )}
                    </CardFooter>
                  ) : (
                    <div />
                  )}
                </CartDesktopContainer>
              </Col>
            )}
            {/* <Footer /> */}
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    config: { config },
    cart: { cart },
  } = state;
  return { cart, config };
};
export default connect(mapStateToProps, {
  addToCart,
  sumFromCart,
  clearCart,
  removeFromCart,
  applyPromoCode,
  setCategories,
  setFromCookie,
  addRedeemItem,
  subFromCart,
  setConfigs,
  loginUser,
  replaceToCart,
})(Categories);
