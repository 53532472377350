import styled from 'styled-components';

import { Tab } from 'react-tabs';
import { Card } from 'reactstrap';

export const StoreTab = styled(Tab)`
  display: inline-block;
  margin-top: 20px;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  width: 50%;
  cursor: pointer;
  border-bottom: ${props => `1px solid ${props.config.headerBottomColor}`};
  background-color: ${({ selected, config }) => {
    return selected ? config.selectedTabColor : config.unSelectedTabColor;
  }};
  color: ${({ selected, config }) => {
    return selected
      ? config.selectedTabTextColor
      : config.unSelectedTabTextColor;
  }};
  @media (max-width: 470px) {
    text-align: center;
    font-size: 15px;
  }
  border-radius: ${props =>
    props?.position === 'left' ? '5px 0 0 5px' : ' 0 5px 5px 0'};
`;

export const StoreCard = styled(Card)`
  margin-top: 10px;
  transition: 0.3s;
  border-width: 2px;
  :hover {
    box-shadow: 0 2px 10px ${props => props.boxShadowColor};
    cursor: pointer;
  }
`;

export const SelectCity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const City = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

export const DropDown = styled.div`
  cursor: pointer;
  margin-left: 7px;
  border: 2px solid #757575;
  border-radius: 7px;
  padding: 5px 10px;
  background-color: #f1f1f1;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #333;
  margin-left: 5px;
  transition: 0.3s;

  &.active {
    transform: rotate(180deg);
  }
`;

export const DropDownContent = styled.ul`
  position: absolute;
  list-style: none;
  top: 200%;
  left: 50%;
  transform: translateX(-50%);

  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 2;
  background-color: white;
  width: 150px;

  opacity: 0;
  visibility: hidden;
  transition: 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
    top: calc(100% + 5px);
  }

  li {
    padding: 10px;
    transition: 0.3s;
    font-size: 15px;
    &:hover {
      background-color: #f1f1f1;
    }
    &.selected {
      background-color: #e6e6e6;
      font-weight: bold;
    }
  }
`;
