import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Button,
  CardBody,
} from 'reactstrap';
import { Tabs, TabList, TabPanel } from 'react-tabs';

import { FaArrowAltCircleDown } from 'react-icons/fa';
import { connect } from 'react-redux';
import Loading from '../loading';
import { fetchStores, fetchDeliveryInformationV2 } from '../../service';
import {
  StoreTab,
  StoreCard,
  SelectCity,
  DropDown,
  DropDownContent,
  City,
  ArrowDown,
} from './style';
import { getSelectedAddress, getSelectedStore } from '../../util';

class StoreSelectionModal extends React.Component {
  state = {
    loading: true,
    stores: [],
    deliveryStores: [],
    cities: [],
    selectedCity: '',
    dropDownCity: false,
  };

  async componentDidMount() {
    const [stores, deliveryStores] = await Promise.all([
      fetchStores(),
      fetchDeliveryInformationV2({
        zip: getSelectedAddress() ? getSelectedAddress().zip : null,
        number: getSelectedAddress() ? getSelectedAddress().number : null,
        street: getSelectedAddress() ? getSelectedAddress().street : null,
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        hoursAfterShceduled: this.props.config.hoursAfterShceduled,
      }),
    ]);
    if (
      this.props.config.hasDelivery === 'YES' &&
      deliveryStores.length === 1 &&
      getSelectedAddress() &&
      this.props.selectTab === 0
    ) {
      this.props.confirmAction({ ...deliveryStores[0], type: 'delivery' });
    }
    const allCities = stores
      .map(store => store.city)
      .concat(deliveryStores.map(store => store.city));
    const cities = [...new Set(allCities)];
    if (getSelectedAddress()) {
      const selectedCity = cities.find(
        city => city === getSelectedAddress().city
      );
      if (selectedCity) {
        this.setState({
          selectedCity,
        });
      }
    }
    this.setState({
      loading: false,
      stores,
      deliveryStores,
      cities,
    });
  }

  filterStoresBySelectedCity = stores =>
    stores.filter(store =>
      this.state.selectedCity ? store.city === this.state.selectedCity : store
    );

  drawStore = ({ stores, prefix, confirmAction }) => {
    return (
      <Row>
        {stores.map(store => (
          <Col
            xs={12}
            style={{ opacity: store.open ? 1 : 0.3 }}
            key={`${prefix}_${store.id}`}
            onClick={() => confirmAction({ ...store, type: prefix })}
          >
            <StoreCard
              boxShadowColor={this.props.config.productCardShadowColor}
            >
              <CardBody>
                <Row>
                  {this.props.config.hideStoreLogo === 'NO' && (
                    <Col
                      lg={2}
                      xs={4}
                      style={{
                        borderRight: '1px solid black',
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        alt={`${store.name} parceira da opdv. Sistema de pdv integrado com ifood, Uber eats e Rappi, o melhor pdv do mercado!`}
                        src={store.logo}
                        style={{ width: '100%' }}
                      />
                    </Col>
                  )}
                  <Col lg={10} xs={12}>
                    <Row>
                      <Col xs={12}>
                        <b style={{ fontSize: 18 }}>{store.name}</b>
                      </Col>
                      {store.address && (
                        <Col xs={12} style={{ fontSize: 14 }}>
                          {prefix === 'takeaway' ? (
                            <span>
                              {store.address.replace(', 0 ()', '')}
                              {store.distance
                                ? `${store.distance.toFixed(2)}km`
                                : ''}
                            </span>
                          ) : (
                            <span>
                              {this.props.config.hasVisibleAddress === 'YES' ? (
                                <>
                                  {store.address.replace(', 0 ()', '')}
                                  {store.distance &&
                                    ` (${store.distance.toFixed(2)}km)`}
                                </>
                              ) : (
                                store.distance &&
                                `Distância do endereço atual até a loja: ${store.distance.toFixed(
                                  2
                                )}km`
                              )}
                            </span>
                          )}
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </StoreCard>
          </Col>
        ))}
      </Row>
    );
  };

  render() {
    const {
      loading,
      stores,
      deliveryStores,
      selectedCity,
      cities,
      dropDownCity,
    } = this.state;
    const { isOpen, confirmAction, cancelAction, changeAddress, selectTab } =
      this.props;
    return (
      <Modal isOpen={isOpen} toggle={() => {}} size="lg">
        {getSelectedAddress() && (
          <ModalHeader>
            <span>
              <b>
                {`${getSelectedAddress().street}/${
                  getSelectedAddress().number
                } ${getSelectedAddress().complement}`}
              </b>
              <FaArrowAltCircleDown
                onClick={changeAddress}
                style={{ marginLeft: 10, cursor: 'pointer' }}
              />
            </span>
          </ModalHeader>
        )}
        <ModalBody>
          <Tabs
            defaultIndex={selectTab}
            style={{
              width: '100%',
              maxHeight: '40wh',
            }}
          >
            <TabList
              style={{
                position: 'sticky',
                top: -22,
                zIndex: 100,
                backgroundColor: 'white',
              }}
            >
              {this.props.config.hasDelivery === 'YES' && (
                <StoreTab
                  config={this.props.config}
                  disabled={loading}
                  onClick={() =>
                    this.setState({ isDelivery: true, isTakeAway: false })
                  }
                  position="left"
                >
                  <strong>Receber em casa</strong>
                </StoreTab>
              )}
              {this.props.config.hasTakeAway === 'YES' && (
                <StoreTab
                  disabled={loading}
                  config={this.props.config}
                  onClick={() =>
                    this.setState({
                      deliveryFee: 0,
                      isDelivery: false,
                      isTakeAway: true,
                      deliveryIsCoverage: false,
                      orderErrorMessage: null,
                      selectedAddressId: null,
                      selectedStoreId: null,
                    })
                  }
                  position="right"
                >
                  <strong>Buscar na loja</strong>
                </StoreTab>
              )}
            </TabList>
            {this.props.config.hasDelivery === 'YES' && (
              <TabPanel>
                {loading && <Loading message="Carregando lojas..." />}
                {!loading && (
                  <div>
                    {deliveryStores.length === 0 && getSelectedAddress() && (
                      <Row>
                        <Col xs={12}>
                          <hr />
                        </Col>
                        <Col xs={12} style={{ margin: 20 }}>
                          <b>
                            Este endereço está fora do nosso raio de entrega
                          </b>
                          <br />
                          <span>{`${getSelectedAddress().street}/${
                            getSelectedAddress().number
                          } ${getSelectedAddress().complement}`}</span>
                        </Col>

                        <Col xs={12}>
                          <Button outline onClick={changeAddress}>
                            Trocar Endereço
                          </Button>
                        </Col>
                        <Col xs={12}>
                          <hr />
                        </Col>
                      </Row>
                    )}

                    {!getSelectedAddress() && (
                      <Row>
                        <Col xs={12}>
                          <hr />
                        </Col>
                        <Col xs={12} style={{ margin: 20 }}>
                          <b>
                            Nenhum endereço informado, por favor, informe um
                            endereço
                          </b>
                        </Col>

                        <Col xs={12}>
                          <Button
                            outline
                            onClick={changeAddress}
                            style={{ margin: '0 20px' }}
                          >
                            Informar endereço
                          </Button>
                        </Col>
                        <Col xs={12}>
                          <hr />
                        </Col>
                      </Row>
                    )}
                    <div>
                      {this.drawStore({
                        confirmAction,
                        stores: deliveryStores,
                        prefix: 'delivery',
                      })}
                    </div>
                  </div>
                )}
              </TabPanel>
            )}
            <TabPanel>
              {loading && <Loading message="Carregando lojas..." />}
              {!loading && (
                <>
                  {this.state.stores.length > 1 && (
                    <SelectCity>
                      <DropDown
                        onClick={() =>
                          this.setState({ dropDownCity: !dropDownCity })
                        }
                      >
                        <City>
                          <span>{selectedCity || 'Filtrar por cidade'}</span>{' '}
                          <ArrowDown
                            className={dropDownCity ? 'active' : undefined}
                          />
                        </City>
                        <DropDownContent
                          className={dropDownCity ? 'active' : undefined}
                        >
                          {cities.map(city => (
                            <li
                              onClick={() =>
                                this.setState({
                                  selectedCity: city,
                                  dropDownCity: false,
                                })
                              }
                              key={city}
                              className={
                                city === selectedCity ? 'selected' : undefined
                              }
                            >
                              {city}
                            </li>
                          ))}
                        </DropDownContent>
                      </DropDown>
                    </SelectCity>
                  )}
                  <div>
                    {this.filterStoresBySelectedCity(
                      stores.filter(({ takeAway }) => takeAway)
                    ).length === 0 && (
                      <Row>
                        <Col xs={12}>
                          <hr />
                        </Col>
                        <Col xs={12} style={{ margin: 20 }}>
                          <b>Nenhum loja tem a opção de retirada</b>
                        </Col>

                        <Col xs={12}>
                          <hr />
                        </Col>
                      </Row>
                    )}
                    {this.drawStore({
                      confirmAction,
                      stores: this.filterStoresBySelectedCity(
                        stores.filter(({ takeAway }) => takeAway)
                      ),
                      prefix: 'takeaway',
                    })}
                  </div>
                </>
              )}
            </TabPanel>
          </Tabs>
        </ModalBody>
        <ModalFooter style={{ display: 'inline-grid' }}>
          {getSelectedStore() && (
            <Row>
              <Col xs={12} style={{ textAlign: 'right' }}>
                <Button onClick={cancelAction} outline>
                  Cancelar
                </Button>
              </Col>
            </Row>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};
export default connect(mapStateToProps, {})(StoreSelectionModal);
